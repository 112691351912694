import React, { useMemo, useState } from 'react';

import AssetsCollectionNavBar from 'components/AssetsCollectionNavBar';
import TextComponentList from 'components/TextComponentList';
import { AssetsCollectionNavItem } from 'const';
import CitationList from 'containers/CitationList';
import ImageList from 'containers/ImageList';
import LayoutList from 'containers/LayoutList';
import * as Models from 'models';
import { isModuleBundle } from 'utils/entityType';
import { intlGet } from 'utils/intlGet';
import { AssetsCollectionEntitiesProps } from './models';
import styles from './styles.module.scss';
import * as utils from './utils';

const AssetsCollectionEntities: React.FunctionComponent<AssetsCollectionEntitiesProps> = (props) => {
  const { document, screenOrder } = props;

  const entities = useMemo(() => utils.getAssetsCollectionEntities(document, screenOrder), [document, screenOrder]);
  const tabs = useMemo(() => utils.getNavItems(document), [document]);
  const disabledTabs = useMemo(() => utils.getDisabledTabs(entities), [entities]);
  const internalDocumentsExist = useMemo(() => utils.internalDocumentsExist(entities, document), [entities, document]);
  const [activeTab, setActiveTab] = useState(() => utils.getActiveTab(tabs, disabledTabs));

  const getContent = (): JSX.Element | null => {
    const { documents } = entities[activeTab];

    switch (activeTab) {
      case AssetsCollectionNavItem.REFERENCE_CITATIONS:
        return <CitationList citations={documents as Models.ReferenceCitationsMap} storyCardId={document.get('id')} />;
      case AssetsCollectionNavItem.TEXTS:
        return <TextComponentList components={documents as Models.TextComponentsMap} showHeader={false} />;
      case AssetsCollectionNavItem.IMAGES:
        return <ImageList documents={documents as Models.ImagesMap} showHeader={false} />;
      case AssetsCollectionNavItem.LAYOUTS:
        return <LayoutList reusableLayouts={documents as Models.ReusableLayoutsMap} storyCardId={document.get('id')} />;

      default: return null;
    }
  };

  return internalDocumentsExist
    ? (
      <div className={styles.AssetsCollectionEntities}>
        {!isModuleBundle(document) &&
        <AssetsCollectionNavBar
          navItems={tabs}
          activeItem={activeTab}
          onChange={setActiveTab}
          disabledItems={disabledTabs}
        />}
        <div className={styles.content}>
          {getContent()}
        </div>
      </div>
    )
    : <div className={styles.empty}>{intlGet('AssetsCollectionEntities', 'NoContent')}</div>;
};

export default AssetsCollectionEntities;
