import * as Models from 'models';

type Option = {
  label: string;
  value: string;
};

export function getScreenTypesOptions(
  screenDefinitions: Models.MasterScreen.ScreenDefinitionsMap,
): Option[] {

  return screenDefinitions.valueSeq().toArray()
    .map(screenDef => ({
      value: screenDef.get('id'),
      label: screenDef.get('defaultName'),
    }))
    .sort((a, b) => {
      if (a.label === b.label) {
        return a.value.localeCompare(b.value);
      }

      return a.label.localeCompare(b.label);
    });
}
