import { MinCellWidth } from 'const';
import * as Models from 'models';
import { isCallToAction, isImage } from 'utils/entityType';

export function getMinCellWidth(
  relation: Models.LayeredRegularRelationMap | Models.LayeredRegularRelation | Models.RegularRelationMap | Models.RegularRelation,
): number {
  switch (true) {
    case isCallToAction(relation as Models.RelationMap): return MinCellWidth.CTA;
    case isImage(relation as Models.RelationMap): return MinCellWidth.IMAGE;
    default: return MinCellWidth.TEXT;
  }
}
