import { CellAction, useCellActionsExecutor } from 'context/CellActions';
import _ from 'lodash';
import React, { useState, useCallback, useEffect } from 'react';
import { intlGet } from 'utils/intlGet';
import useAbbreviation from '../../../hooks/useAbbreviation';
import AbbreviationDetails from '../../AbbreviationDetails';
import css from '../styles.module.scss';
import AbbreviationsList from './AbbreviationsList';
import NewRelationAbbreviation from './NewRelationAbbreviation';
import SelectedText from './SelectedText';

type Props = {
  selectedAbbreviationIDs: string[];
  onAdd: (id: string) => void;
  onReplace: (prevId: string, newId: string) => void;
  onRemove: (id: string) => void;
  onClose: () => void;
};

const RelationAbbreviationPanel: React.FunctionComponent<Props> = (props) => {
  const { selectedAbbreviationIDs, onAdd, onReplace, onRemove, onClose } = props;

  const abbreviationDocuments = useAbbreviation(selectedAbbreviationIDs);
  const usedTerms = _.compact(abbreviationDocuments).map(({ abbreviation }) => abbreviation.term);

  const [editedTerm, setEditedTerm] = useState('');
  const [editedAbbreviationId, setEditedAbbreviationId] = useState('');

  const onSelectAbbreviationDocument = useCallback((id: string) => {
    if (editedTerm === '') {
      return;
    }
    if (editedAbbreviationId) {
      onReplace(editedAbbreviationId, id);
    } else {
      onAdd(id);
    }
    setEditedTerm('');
    setEditedAbbreviationId('');
  }, [editedTerm, editedAbbreviationId]);

  useEffect(() => {
    if (!editedTerm) {
      setEditedAbbreviationId('');
    }
  }, [editedTerm]);

  useCellActionsExecutor(CellAction.CHANGE_ABBREVIATION, (id) => {
    const document = abbreviationDocuments.find(doc => doc.id === id);
    if (!document) {
      return false;
    }
    setEditedTerm(document.abbreviation.term);
    setEditedAbbreviationId(document.id);

    return true;
  });

  if (editedTerm) {
    return (
      <div className={css.flexContainer}>
        <div className="d-flex justify-content-between align-items-start mb-2">
          <SelectedText text={editedTerm} />
          <button
            className={css.button}
            onClick={(): void => {
              setEditedTerm('');
              setEditedAbbreviationId('');
            }}
          >
            {intlGet('EditorToolbar.Abbreviation', 'Button.Cancel')}
          </button>
        </div>
        <AbbreviationsList
          className={css.scroll}
          term={editedTerm}
          abbreviationDocument={editedAbbreviationId ? abbreviationDocuments.find(({ id }) => id === editedAbbreviationId) : undefined}
          onSelect={onSelectAbbreviationDocument}
        />
      </div>
    );
  }

  return (
    <>
      {abbreviationDocuments.map((abbreviationDocument) => {
        if (!abbreviationDocument) {
          return null;
        }

        return (
          <AbbreviationDetails
            key={abbreviationDocument.id}
            abbreviation={abbreviationDocument.abbreviation}
            onChange={(): void => {
              setEditedTerm(abbreviationDocument.abbreviation.term);
              setEditedAbbreviationId(abbreviationDocument.id);
            }}
            onRemove={(): void => onRemove(abbreviationDocument.id)}
          />
        );
      })}

      <NewRelationAbbreviation
        onAdd={setEditedTerm}
        usedTerms={usedTerms}
        onCancel={abbreviationDocuments.length ? undefined : onClose }
      />
    </>
  );
};

export default RelationAbbreviationPanel;
