import classNames from 'classnames';
import React from 'react';

import AccessibleDiv from 'components/AccessibleDiv/AccessibleDiv';
import Icon from 'components/Icon';
import { IconType } from 'components/Icon/constants';
import { HeaderProps } from './models';
import styles from './styles.module.scss';

const Header: React.FunctionComponent<HeaderProps> = ({ expanded, title, onClick, showUpdatesHint = false }) => (
  <AccessibleDiv
    className={classNames(styles.Header, {
      [styles.expanded]: expanded,
      [styles.updatesHint]: showUpdatesHint,
    })}
    onClick={onClick}
  >
    <div className={styles.title}>{title}</div>
    <div>
      <Icon type={expanded ? IconType.CHEVRON_UP : IconType.CHEVRON_DOWN} size="badge" color="primary" />
    </div>
  </AccessibleDiv>
);

export default Header;
