import React, { PropsWithChildren, RefObject, useEffect, useMemo, useState } from 'react';
import { registerCellActionsAPI, unregisterCellActionsAPI } from './api';
import { CellActionsContext, CellActionsContextType } from './CellActionsContext';
import { CellAction, CellActionArguments, CellActionExecutor, CellActionRequest } from './types';

function requests2contextActions(requests: CellActionRequest[]): CellActionsContextType['actions'] {
  return requests.map((request) => {
    const [name, ...args] = Array.isArray(request) ? request : [request];

    return {
      name,
      args: args as CellActionArguments<CellAction>,
    };
  });
}

type Props = PropsWithChildren<{
  relationId: string;
  toggleEditModeOn: () => void;
  toggleEditModeOff: () => void;
  containerRef: RefObject<HTMLDivElement>;
}>;

export function CellActionsProvider(props: Props): React.ReactElement {
  const { children, relationId, toggleEditModeOn, toggleEditModeOff, containerRef } = props;

  const [actions, setActions] = useState<CellActionsContextType['actions']>([]);
  let numberOfExecutedActions = 0; // should not be wrapped with useRef

  const context = useMemo<CellActionsContextType>(() => {
    if (!actions.length) {
      return { relationId, actions };
    }

    let executionShouldBeAborted = false;

    return {
      relationId,
      actions,
      tryToExecuteAction: <A extends CellAction>(
        name: A,
        executor: CellActionExecutor<A>,
      ): void => {
        const actionToExecute = actions.find(a => !a.isExecuted);
        if (actionToExecute?.name !== name || executionShouldBeAborted) {
          return;
        }
        actionToExecute.isExecuted = true;
        numberOfExecutedActions += 1;
        const result = executor(...actionToExecute.args as CellActionArguments<A>);
        executionShouldBeAborted = result === false;
        if (executionShouldBeAborted) {
          // eslint-disable-next-line no-console
          console.error('action terminated', name, relationId);
          setActions([]);
        } else {
          setActions(list => list.filter(a => a !== actionToExecute));
        }
      },
    };
  }, [relationId, actions]);

  useEffect(() => {
    registerCellActionsAPI(relationId, (...requests: CellActionRequest[]) => {
      setActions(requests2contextActions(requests));
    });

    return (): void => unregisterCellActionsAPI(relationId);
  }, [relationId]);

  useEffect(() => {
    context.tryToExecuteAction?.(CellAction.TOGGLE_EDITMODE_ON, (scrollIntoView = false) => {
      toggleEditModeOn();
      if (scrollIntoView) {
        containerRef.current?.scrollIntoView({ block: 'center' });
      }
    });

    context.tryToExecuteAction?.(CellAction.TOGGLE_EDITMODE_OFF, () => {
      toggleEditModeOff();
    });

  }, [context]);

  useEffect(() => {
    if (!numberOfExecutedActions && actions.length) {
      // eslint-disable-next-line no-console
      console.error('abort actions (missing render)', actions, relationId);
      setActions([]);
    }
  });

  return (
    <CellActionsContext.Provider value={context}>
      {children}
    </CellActionsContext.Provider>
  );
}
