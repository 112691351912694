import { EditorState } from 'draft-js';
import _ from 'lodash';
import { applyEditorStateFontStylesForBrandStyles } from 'modules/draftjs';
import { RefObject } from 'react';
import * as Constants from 'const';
import * as Models from 'models';
import * as editorUtils from 'utils/editor';
import { getReferenceNumberOrder } from 'utils/getReferenceNumberOrder';
import { BrandProps } from '../hooks/useBrandProps';
import useEditor from '../hooks/useEditor';
import { TextProps } from '../models';
import { getEditorStateFromProps } from './editor';
import { getPrioritizedRelation } from './relation';

export const orderChange = (
  props: TextProps,
  brandProps: BrandProps,
  editorHook: ReturnType<typeof useEditor>,
  prevPropsRef: RefObject<TextProps>,
  currentEditorState: EditorState,
): void => {

  if (!prevPropsRef.current) {
    return;
  }

  const { document, referenceOrder, editMode, projectType } = props;
  const { fonts } = brandProps;
  const referencesOrder = getReferenceNumberOrder(
    getPrioritizedRelation(props),
    referenceOrder,
  );
  const prevReferencesOrder = getReferenceNumberOrder(
    getPrioritizedRelation(prevPropsRef.current),
    prevPropsRef.current.referenceOrder,
  );
  const referenceOrderChanged = referencesOrder !== prevReferencesOrder
      && referencesOrder.some((ref, idx = 0) => ref !== prevReferencesOrder.get(idx));

  if (document !== prevPropsRef.current.document || referenceOrderChanged || prevPropsRef.current.editMode !== editMode) {
    currentEditorState = getEditorStateFromProps(props, brandProps);
    currentEditorState = applyEditorStateFontStylesForBrandStyles(currentEditorState, projectType, fonts);
    editorHook.setEditorStateAndOperations(currentEditorState);
  }
};

export const updateFonts = (
  fonts: Models.BrandFontsList,
  editorHook: ReturnType<typeof useEditor>,
): void => {
  const currentEditorState = editorHook.editorState;
  let newFontFamily = editorUtils.getActiveFontFamily(currentEditorState);

  // don't need to show applied font family if there is no such font in Brand Definition
  // show default font instead in this case
  if (fonts && !fonts.some(font => font?.get('name') === newFontFamily.fontFamily)) {
    newFontFamily = {
      fontFamily: Constants.DefaultCustomStyle.FONT_FAMILY as string,
      characterStyle: undefined,
    };
  }

  if (!_.isEqual(editorHook.activeFontFamily, newFontFamily)) {
    editorHook.setActiveFontFamily(newFontFamily);
  }
};
