import classNames from 'classnames';
import React, { useState } from 'react';
import AnimateHeight from 'react-animate-height';
import { DragSource, DragSourceCollector, DragSourceSpec } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';

import AccessibleDiv from 'components/AccessibleDiv/AccessibleDiv';
import AssetActions from 'components/AssetActions';
import AssetsCollectionEntities from 'components/AssetsCollectionEntities';
import DocumentThumbnail from 'components/DocumentThumbnail';
import Icon from 'components/Icon';
import { IconType } from 'components/Icon/constants';
import { DragSourceType, EntityType } from 'const';
import * as Models from 'models';
import { isModuleBundle } from 'utils/entityType';
import { AssetsCollectionScreenCollectedProps, AssetsCollectionScreenOwnProps, AssetsCollectionScreenProps } from './models';
import styles from './styles.module.scss';

const AssetItemDragSource: DragSourceSpec<AssetsCollectionScreenProps, Models.StoryCardDragObject> = {
  beginDrag(props) {
    const { document, screenOrder, screen, toggleDragState } = props;
    toggleDragState(DragSourceType.STORY_CARD, EntityType.STORY_CARD);

    return {
      storyCardId: document.get('id'),
      screenOrder,
      screenName: screen.get('name'),
      previewUrl: screen.getIn(['thumbnail', 'url']),
    };
  },

  canDrag(props) {
    return !isModuleBundle(props.document);
  },

  endDrag(props) {
    props.toggleDragState();
  },
};

const collect: DragSourceCollector<AssetsCollectionScreenCollectedProps, AssetsCollectionScreenOwnProps> = (
  connect,
  monitor,
) => ({
  connectDragPreview: connect.dragPreview(),
  connectDragSource: connect.dragSource(),
  draggable: monitor.canDrag(),
  isDragging: monitor.isDragging(),
});

const AssetsCollectionScreen: React.FunctionComponent<AssetsCollectionScreenProps> = (props) => {
  const {
    document,
    screen,
    screenOrder,
    showDetails,

    connectDragPreview,
    connectDragSource,
    draggable,
    isDragging,
  } = props;

  const [expanded, setExpanded] = useState(false);
  const toggleExpanding = (): void => setExpanded(!expanded);

  const name = screen.get('name');
  const thumbnailUrl = screen.getIn(['thumbnail', 'url']);
  const onPreviewOpen = (): void => showDetails(thumbnailUrl);

  React.useEffect(
    () => { connectDragPreview(getEmptyImage(), { captureDraggingState: true }); },
    [],
  );

  return connectDragSource((
    <div className={classNames(styles.AssetsCollectionScreen, { [styles.draggable]: draggable, [styles.isDragging]: isDragging })}>
      <div className={styles.contentInvisible}>
        <div className={styles.screen}>
          <div className={styles.preview}>
            <DocumentThumbnail
              placeholder={<Icon type={IconType.IMAGE} size="sm-md" color="grey" />}
              url={thumbnailUrl}
            />
            {thumbnailUrl && <AssetActions onPreviewOpen={onPreviewOpen} />}
          </div>
          <AccessibleDiv
            className={styles.toggleArea}
            onClick={toggleExpanding}
          >
            <div className={styles.info}>{name}</div>
            <div className={styles.chevron}>
              <Icon
                type={expanded ? IconType.CHEVRON_UP : IconType.CHEVRON_DOWN}
                size="badge"
                color="primary"
              />
            </div>
          </AccessibleDiv>
        </div>
        <AnimateHeight
          duration={300}
          easing="ease-out"
          height={expanded ? 'auto' : 0}
        >
          <AssetsCollectionEntities document={document} screenOrder={screenOrder} />
        </AnimateHeight>
      </div>
    </div>
  ));
};

export default DragSource<AssetsCollectionScreenOwnProps, AssetsCollectionScreenCollectedProps, Models.StoryCardDragObject>(
  DragSourceType.STORY_CARD,
  AssetItemDragSource,
  collect,
)(AssetsCollectionScreen);
