import _ from 'lodash';
import React, { useState } from 'react';

import { ControlState } from 'components/Toolbar/Controls/Control/constants';
import { useClickOutsideWithToggle } from 'hooks/useClickOutside';
import { Priority } from 'hooks/useClickOutside/constants';
import * as Models from 'models';

let clickTimer: number = null;

interface ControlWithDropdownOptions {
  controlState?: Models.ControlState;
  closeDropdownCallback?(): void;
  priority?: Priority;
}

export const useControlWithDropdown = (options: ControlWithDropdownOptions = {}) => {
  const {
    controlState = ControlState,
    closeDropdownCallback = _.noop,
    priority = Priority.CONTROL,
  } = options;

  const {
    on: isOpen,
    toggle: toggleDropdown,
    container: control,
  } = useClickOutsideWithToggle<HTMLDivElement>(priority, { closeDropdownCallback, useToolbar: false });
  const state = isOpen ? controlState.ACTIVE : controlState.DEFAULT;

  return {
    control,
    isOpen,
    state,
    toggleDropdown,
  };
};

export const useControlWithFeedback = ({
  controlState = ControlState,
  toggleControl,
}: {
  controlState?: Models.ControlState;
  toggleControl: () => void;
}) => {
  const [recentClick, setRecentClick] = React.useState(false);

  const state = recentClick ? controlState.ACTIVE : controlState.DEFAULT;
  const toggle = (): void => {
    clearTimeout(clickTimer);

    setRecentClick(true);
    toggleControl();

    clickTimer = window.setTimeout(() => setRecentClick(false), 100);
  };

  return {
    state,
    toggleControl: toggle,
  };
};

export const useControlWithTooltip = () => {
  const [hoverOnControl, toggleHoverOnControl] = useState(false);
  const [hoverOnDropdown, toggleHoverOnDropdown] = useState(false);

  const onControlEnter = () => {
    toggleHoverOnControl(true);
  };

  const onDropdownEnter = () => {
    toggleHoverOnDropdown(true);
  };

  const onDropdownLeave = () => {
    toggleHoverOnDropdown(false);
  };

  const onControlLeave = () => {
    toggleHoverOnControl(false);
  };

  return {
    hoverOnControl,
    hoverOnDropdown,
    onControlEnter,
    onDropdownEnter,
    onControlLeave,
    onDropdownLeave,
  };
};
