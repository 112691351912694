import * as Models from 'models';
import { isCallToAction, isTextComponent } from 'utils/entityType';
import { removeAbbreviationsIfNotPresent } from '../utils/removeAbbreviationsIfNotPresent';

type ArgsType = Models.GetProjectDataProcessArgs | Models.RestoreLayoutAssetsArgs;


export function removeMissingAbbreviations<T extends ArgsType>(args: T): T {
  const { documents } = args.assets;
  const { textAbbreviationDocuments } = args;

  for (const document of Object.values(documents)) {
    if ((isTextComponent(document) || isCallToAction(document)) && document.rawContent) {
      document.rawContent = removeAbbreviationsIfNotPresent(textAbbreviationDocuments, document.rawContent);
    }
  }

  return args;
}
