import Draft from 'draft-js';
import { Options } from 'draft-js-export-html';
import _ from 'lodash';

import * as Constants from 'const';
import * as Models from 'models';
import { getCustomInlineStyle } from './style';

export function getReferenceCitationStateToHTMLOptions(): Options {
  const getEntityStyles = (entity: Draft.EntityInstance) => {
    if (entity.getType() === Constants.DraftEntity.LINK) {
      const { url } = entity.getData();

      return {
        element: 'a',
        attributes: {
          href: url,
          title: url,
          target: '_blank',
        },
      };
    }

    return null;
  };

  return {
    entityStyleFn: getEntityStyles,
    inlineStyles: {
      [Constants.ScriptType.SUPERSCRIPT]: {
        element: 'sup',
      },
      [Constants.ScriptType.SUBSCRIPT]: {
        element: 'sub',
      },
    },
  };
}

export function getXliffTextStateToHTMLOptions(): Options {
  const getEntityStyles = (entity: Draft.EntityInstance) => {
    switch (entity.getType()) {
      case Constants.DraftEntity.LINK: {
        const { url } = entity.getData();

        return {
          element: 'a',
          attributes: {
            href: url,
            title: url,
            target: '_blank',
          },
        };
      }
      case Constants.DraftEntity.REFERENCE: {
        const { id } = entity.getData();

        return {
          element: 'sup',
          attributes: {
            // replace with constant
            'data-reference-id': id,
          },
        };
      }
      default: return null;
    }
  };

  // In order to avoid wrapping of empty blocks.
  // By default we get, e.g. <p><br/></p> that cause an extra block when we create editor state from html
  // Tag <br/> is interpreted as new list item after bulleted list when we converting html to editor state back.
  // Need to replace <p><br/></p> with <p></p>
  const blockRenderers = [
    Constants.TextHorizontalAlignmentType.CENTER,
    Constants.TextHorizontalAlignmentType.JUSTIFY,
    Constants.TextHorizontalAlignmentType.LEFT,
    Constants.TextHorizontalAlignmentType.RIGHT,
    Constants.UNSTYLED_BLOCK_TYPE,
  ].reduce(
    (blockRenderers, blockType) => {
      blockRenderers[blockType] = block => block.getText() === '' ? '<p></p>' : null;

      return blockRenderers;
    },
    {} as Options['blockRenderers'],
  );

  return {
    blockRenderers,
    entityStyleFn: getEntityStyles,
    inlineStyles: {
      [Constants.ScriptType.SUPERSCRIPT]: {
        element: 'sup',
      },
      [Constants.ScriptType.SUBSCRIPT]: {
        element: 'sub',
      },
    },
  };
}

export function getTextStateToHTMLOptions(colors: Models.BrandColorsList, fonts: Models.BrandFontsList): Options {
  const getInlineStyle = (styles: Draft.DraftInlineStyle): { style: Models.CSSInlineStyle; element: string } => ({
    style: getCustomInlineStyle(colors, fonts)(styles),
    element: 'span',
  });

  const getBlockStyle = (block: Draft.ContentBlock): Models.CSSInlineStyle | null | { style: Models.CSSInlineStyle } => {
    const style = {};
    const blockType = block.getType();
    const blockLineHeight: Constants.TextLineHeightValue = block.getData().get(Constants.BlockDataKey.LINE_HEIGHT);
    const alignmentType = Constants.HORIZONTAL_TEXT_ALIGNMENTS.find(alignmentType => alignmentType.type === blockType);
    const lineHeight = Constants.TEXT_LINE_HEIGHTS.find(lineHeight => lineHeight.type === blockLineHeight);

    if (alignmentType) {
      style['text-align'] = alignmentType.type;
    }
    if (lineHeight) {
      style['line-height'] = String(lineHeight.type);
    }

    return _.isEmpty(style) ? null : { style };
  };

  return {
    defaultBlockTag: 'div',
    blockStyleFn: getBlockStyle,
    inlineStyleFn: getInlineStyle,
  } as Options;
}
