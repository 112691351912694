import classNames from 'classnames';
import Draft from 'draft-js';
import { useCallback } from 'react';
import * as Constants from 'const';
import * as inlineStyles from 'utils/inlineStyles';
// IN-PROGRESS: move draftjs related styles to different file
import css from '../../../components/ArtboardAssets/Text/styles.module.scss';

function getHorizontalAlignmentStyle(block: Draft.ContentBlock): string | null {
  switch (block.getType() as Constants.TextHorizontalAlignmentType) {
    case Constants.TextHorizontalAlignmentType.LEFT:
      return css.alignLeft;
    case Constants.TextHorizontalAlignmentType.CENTER:
      return css.alignCenter;
    case Constants.TextHorizontalAlignmentType.RIGHT:
      return css.alignRight;
    case Constants.TextHorizontalAlignmentType.JUSTIFY:
      return css.alignJustify;
    default:
      return null;
  }
}

function getLineHeightStyle(
  block: Draft.ContentBlock,
  projectType: Constants.ProjectType,
): string | null {
  const { allowCustomRangeFontSizeSelection } = Constants.ProjectsConfig[projectType];
  const lineHeight: Constants.TextLineHeightValue = block.getData().get(Constants.BlockDataKey.LINE_HEIGHT)
        || Constants.DefaultLineHeight;

  // For Email projects font size is changeable only for whole paragraph, so style with font from first character could be source of truth
  // Microsoft Outlook has restriction where line-height should be in pixels and go together with font-size
  // So this code branch exists to make output of HTML and Artboard equal
  if (!allowCustomRangeFontSizeSelection && lineHeight) {
    const fontSizeStyle = inlineStyles.findFontSizeStyle(block.getInlineStyleAt(0));

    if (fontSizeStyle) {
      const fontSize = inlineStyles.getFontSizeFromStyle(fontSizeStyle);
      const lineHeightInPx = Math.round(lineHeight * fontSize);

      return classNames(
        css[`line-height_${String(lineHeight).replace('.', '-')}`],
        css[`line-height_${lineHeightInPx}px`],
        css[`font-size_${fontSize}px`],
      );
    }
  }

  switch (lineHeight) {
    case Constants.TextLineHeightValue.ONE:
      return css['line-height_1'];
    case Constants.TextLineHeightValue.ONE_POINT_TWO:
      return css['line-height_1-2'];
    case Constants.TextLineHeightValue.ONE_POINT_FIVE:
      return css['line-height_1-5'];
    case Constants.TextLineHeightValue.TWO:
      return css['line-height_2'];
    default:
      return null;
  }
}

type BlockStyleFn = (block: Draft.ContentBlock) => string;

export function useTextEditorBlockStyleFn(
  projectType: Constants.ProjectType,
): BlockStyleFn {
  return useCallback((block: Draft.ContentBlock): string => {
    return classNames(
      getHorizontalAlignmentStyle(block),
      getLineHeightStyle(block, projectType),
    );
  }, [projectType]);
}
