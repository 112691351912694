import { useSelector } from 'react-redux';
import * as relationsSelectors from 'containers/Relations/selectors';
import * as Models from 'models';

export function useLayoutRegularFirstLayeredRelations<R extends Models.LayeredRegularRelation>(
  layout: Models.LayoutMap,
): DeepIMap<R> {
  const relationsByRegularLayoutId = useSelector(relationsSelectors.relationsByRegularLayoutId);

  const layoutRelations = relationsByRegularLayoutId.get(layout.get('id')) as unknown as Models.RelationsMap;
  const layoutRelationId = layout.get('relationId');
  const layoutRelation = layoutRelations.get(layoutRelationId) as Models.ColumnRelationMap;
  const rowRelationId = layoutRelation.get('relationIds').first();
  const rowRelation = layoutRelations.get(rowRelationId) as Models.RowRelationMap;
  const relationId = rowRelation.get('relationIds').first();

  return layoutRelations.get(relationId) as DeepIMap<R>;
}

