import { useCallback, useRef } from 'react';
import * as Models from 'models';
import * as brandStylesUtils from 'utils/brandStyles';
import { BrandProps } from './useBrandProps';
import useCell from './useCell';
import useStyles from './useStyles';

type UseBrandStyleChange = {
  setBrandStyle: (style: Models.BrandStyleMap) => void;
};

export default function useBrandStyleChange(
  brandProps: BrandProps,
  editorApplyBrandStyleValues: (brandStyleValues: Models.TextBrandStyles, brandProps: BrandProps) => void,
  cellHook: ReturnType<typeof useCell>,
  stylesHook: ReturnType<typeof useStyles>,
): UseBrandStyleChange {

  const brandPropsRef = useRef(brandProps);
  brandPropsRef.current = brandProps;

  const { props: { isAutoFitContent }, toggleAutoFitContent } = cellHook;
  const { applyBrandStyle: stylesApplyBrandStyle } = stylesHook;

  const setBrandStyle = useCallback((brandStyle: Models.BrandStyleMap): void => {
    const { colors, fonts } = brandPropsRef.current;
    const textStyles = brandStylesUtils.getTextStylesFromBrandStyle(brandStyle, colors, fonts);

    editorApplyBrandStyleValues(textStyles, brandPropsRef.current);
    if (!isAutoFitContent) {
      toggleAutoFitContent();
    }
    stylesApplyBrandStyle(brandStyle, textStyles, false);
  }, [
    editorApplyBrandStyleValues,
    isAutoFitContent, toggleAutoFitContent,
    stylesApplyBrandStyle,
  ]);

  return { setBrandStyle };
}
