import { AssetAlignment } from 'models';

export interface ResizeComponentCommonProps {
  container: HTMLElement;
  alignment: AssetAlignment;
  // isResponsive is true if need to save component ratio, otherwise isResponsive is false
  isResponsive: boolean;
  // required only if props.isResponsive is true
  // it affects to used formulas
  isComponentWide?: boolean;
  minHeight?: number;
  minWidth?: number;
  getStyle?(scale: number): React.CSSProperties;
}

// Uses only when props.isResponsive is true
export interface ResizeComponentResponsiveProps extends ResizeComponentCommonProps {
  scale: number;
  finishResizing: (scale: number) => void;
  startResizing?: (scale: number) => void;
  onResize?: (scale: number) => void;
}

// Uses only when props.isResponsive is false
export interface ResizeComponentNonResponsiveProps extends ResizeComponentCommonProps {
  finishResizing: (width: number, height: number) => void;
  startResizing?: (width: number, height: number) => void;
  onResize?: (height: number) => void;
  height: number;
  width: number;
}

export enum ResizeType {
  TOP = 'top',
  BOTTOM = 'bottom',
}

export type ResizeComponentProps = ResizeComponentResponsiveProps | ResizeComponentNonResponsiveProps;

export interface ResizeComponentState {
  containerWidth: number;
  containerExtraHeight: number;
  containerExtraWidth: number;
  prevScale: number;
  previousDelta: number;
  boundingComponentRect: {
    top: number;
    right: number;
    bottom: number;
    left: number;
  };

  // Uses only when props.isResponsive is true
  // value takes from 0 till 1 (e.g. 0.3941)
  scale: number;

  // Uses only when props.isResponsive is false
  // values in pixels
  height: number;
  width: number;
  // width / height
  ratio: number;
  // need to know the component takes all height of container or not
  // it affects to used formulas
  fullHeight: boolean;
  // need to know the fullHeight property was changed during resizing or not
  // it affects to used formulas
  isFullHeightChanged: boolean;
  // this value uses when isFullHeightChanged is true, because need to calculate new height during resizing using this
  tempHeight: number;
  // width from props cannot be used because in case when width more than container width, need to save actual width
  prevWidth: number;
  // component ratio can be changed during resizing and need to calculate it before every resizing
  // it affects to used formulas
  isComponentWide: boolean;
}
