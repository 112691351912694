import classNames from 'classnames';
import React from 'react';
import Icon from 'components/Icon';
import { IconType } from 'components/Icon/constants';
import { BackgroundGradientDirection } from 'models/styles/style/BackgroundGradient';
import styles from './styles.module.scss';

const DIRECTIONS: Record<BackgroundGradientDirection, IconType> = {
  [BackgroundGradientDirection.DEG0]: IconType.ARROW_UP,
  [BackgroundGradientDirection.DEG90]: IconType.ARROW_RIGHT,
  [BackgroundGradientDirection.TOP_RIGHT]: IconType.ARROW_UP_RIGHT,
  [BackgroundGradientDirection.BOTTOM_RIGHT]: IconType.ARROW_DOWN_RIGHT,
  [BackgroundGradientDirection.RADIAL]: IconType.ARROW_ROTATE,
};

type Props = {
  value?: BackgroundGradientDirection;
  onChange: (value: BackgroundGradientDirection) => void;
  className?: string;
};

const GradientDirection: React.FunctionComponent<Props> = (props) => {
  const { value, onChange, className } = props;

  return (
    <div className={classNames(className, styles.gradientDirection)}>
      {Object.values(BackgroundGradientDirection).map((direction) => {
        const icon = DIRECTIONS[direction];
        const active = direction === value;

        return (
          <button
            key={direction}
            onClick={(): void => onChange(direction)}
            className={classNames('control', { active })}
          >
            <Icon className={styles.closeIcon} type={icon} size="sm-md" color={ active ? 'primary' : 'grey'} />
          </button>
        );
      },
      )}
    </div>
  );
};

export default GradientDirection;
