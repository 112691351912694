import Draft from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import Immutable from 'immutable';
import _ from 'lodash';
import { useCallback, useRef } from 'react';
import * as Constants from 'const';
import * as relationsActions from 'containers/Relations/actions';
import { textComponentFactory } from 'factories/document/textComponentFactory';
import * as Models from 'models';
import { removeReferenceCitationsOrder } from 'utils/addReferenceCitationsOrder';
import * as editorUtils from 'utils/editor';
import { prioritizeLayeredRelations } from 'utils/prioritizeLayeredRelations';
import { removeTags } from 'utils/removeTags';
import { stylesComparator } from 'utils/styles/stylesComparator';
import useStyles from '../hooks/useStyles';
import { TextProps } from '../models';
import { stylesToSource } from '../utils/styles';
import { BrandProps } from './useBrandProps';
import useCell from './useCell';
import useEditor from './useEditor';

type SaveHandler = () => void;

type EditorProps = {
  hasTextContent: boolean;
};

export default function useSave(
  props: TextProps,
  brandProps: BrandProps,
  editorProps: EditorProps,
  editorHook: ReturnType<typeof useEditor>,
  cellHook: ReturnType<typeof useCell>,
  stylesHook: ReturnType<typeof useStyles>,
): SaveHandler {

  const propsRef = useRef(props);
  propsRef.current = props;

  const brandPropsRef = useRef(brandProps);
  brandPropsRef.current = brandProps;

  const editorPropsRef = useRef(editorProps);
  editorPropsRef.current = editorProps;

  const editorHookRef = useRef(editorHook);
  editorHookRef.current = editorHook;

  const stylesHookRef = useRef(stylesHook);
  stylesHookRef.current = stylesHook;

  const cellHookRef = useRef(cellHook);
  cellHookRef.current = cellHook;

  return useCallback((): void => {
    const {
      activeLayer,
      addTextToCell,
      batchActions,
      country,
      document,
      language,
      layoutId,
      layoutRelations,
      relation,
      sectionStyles,
    } = propsRef.current;

    const { colors, fonts } = brandPropsRef.current;
    const { props: { isAutoFitContent, cellHeightChanged, cellWidthChanged } } = cellHookRef.current;
    const { hasTextContent: editorHasTextContent } = editorPropsRef.current;

    const updatedRelation = relation.updateIn(['styles', activeLayer], values => values.withMutations(
      value => stylesToSource(stylesHookRef.current.styles, value)
        .set('isAutoFitContent', isAutoFitContent),
    ));
    const updatedRelations = layoutRelations.set(updatedRelation.get('id'), updatedRelation);
    const areStylesEqual = _.isEqualWith(
      updatedRelation.getIn(['styles', activeLayer]).toJS(),
      relation.getIn(['styles', activeLayer]).toJS(),
      stylesComparator,
    );

    if (editorHasTextContent) {
      // no need to save text if there are no changes
      if (editorHookRef.current.operations.length === 0 && areStylesEqual) {
        // need to set relations because cell height/width may have been modified while edit mode was active
        if (cellWidthChanged || cellHeightChanged) {
          relationsActions.updateLayeredRelations(updatedRelations);
        }

        return;
      }

      const text = stateToHTML(
        editorHookRef.current.editorState.getCurrentContent(),
        editorUtils.getTextStateToHTMLOptions(colors, fonts),
      );
      const rawContent = JSON.stringify(Draft.convertToRaw(removeReferenceCitationsOrder(editorHookRef.current.editorState)));
      let updatedTextComponent: Models.TextComponentMap;

      if (!document) {
        updatedTextComponent = Immutable.fromJS(textComponentFactory({
          id: relation.getIn(['documentId', activeLayer], undefined),
          rawContent,
          status: Constants.TextStatusType.EDIT,
          text,
          language: language.toJS(),
          country: country.toJS(),
        }));
      } else {
        updatedTextComponent = document.withMutations(doc => doc
          .set('rawContent', rawContent)
          .set('status', Constants.TextStatusType.EDIT)
          .set('text', text)
          .set('name', removeTags(text))
          // https://issues.merck.com/browse/DCC-4748
          .set('language', language)
          .set('country', country),
        );
      }
      const prioritizedUpdatedRelations = prioritizeLayeredRelations(updatedRelations, activeLayer);
      addTextToCell(
        updatedRelation.get('id'),
        prioritizedUpdatedRelations,
        updatedTextComponent,
        layoutId,
        editorHookRef.current.operations,
        sectionStyles,
      );

      editorHookRef.current.setOperations([]);
      cellHookRef.current.resetCellWidhtAndHeightChange();

      return;
    }

    const actions: Models.IAction[] = [];

    if (cellWidthChanged || cellHeightChanged || !areStylesEqual) {
      actions.push(relationsActions.updateLayeredRelations(updatedRelations));
    }

    if (document) {
      actions.push(relationsActions.deleteComponents([document.get('id')]));
    }

    if (actions.length !== 0) {
      batchActions(actions);
    }

    editorHookRef.current.setOperations([]);
    cellHookRef.current.resetCellWidhtAndHeightChange();
  }, []);
}
