import { LexicalEditor } from 'lexical';
import { LexicalAbbreviationValues } from 'modules/Abbreviations/Lexical/AbbreviationPlugin';
import { ABBREVIATION_CHANGE } from 'modules/Abbreviations/Lexical/AbbreviationPlugin/commands';
import { $selectAbbreviation } from 'modules/Abbreviations/Lexical/AbbreviationPlugin/utils';
import { BASE_PROPS_COMMAND, BaseProps } from 'modules/Lexical/Plugins/BasePropsPlugin';
import { BULLET_COLOR_COMMAND } from 'modules/Lexical/Plugins/BulletColorPlugin';
import { FONT_COMMAND, FontProps } from 'modules/Lexical/Plugins/FontPlugin';
import { LINK_COMMAND, LinkProps } from 'modules/Lexical/Plugins/LinkNodePlugin';
import { RefObject, useCallback, useMemo, useRef, useState } from 'react';
import * as Constants from 'const';
import * as Models from 'models';
import { BrandProps } from './useBrandProps';
import { TextEditorProps, TextEditorSetters } from './useEditor';

export type LexicalOnChange = {
  abbreviations: (values: LexicalAbbreviationValues) => void;
  baseProps: (values: BaseProps) => void;
  bulletColor: (value: Models.BrandColorMap | undefined) => void;
  font: (values: FontProps) => void;
  link: (values: LinkProps) => void;
  textContent: (value: string) => void;
};

type LexicalTextEditorHook = {
  ref: RefObject<LexicalEditor>;
  textContent: string;
  onChange: LexicalOnChange;
  editor: LexicalEditor | null;
  props: TextEditorProps;
  setters: TextEditorSetters;
  applyBrandStyleValues: (brandStyleValues: Models.TextBrandStyles, brandProps: BrandProps) => void;
  chooseAbbreviation: (abbreviationId: string, abbreviationNumber: number | undefined) => boolean;
};

export function useLexicalTextEditor(): LexicalTextEditorHook {
  const ref = useRef<LexicalEditor>(null);
  const editor = ref.current;

  // IN-PROGRESS: get initial props should be read from initial editor state ?
  const [props, setProps] = useState<TextEditorProps>({
    blockLineHeight: Constants.TextLineHeightValue.ONE,
    blockType:  Constants.TextHorizontalAlignmentType.LEFT,
  } as TextEditorProps);

  // IN-PROGRESS: get initial value from props
  const [textContent, setTextContent] = useState('');

  const setters = useMemo((): TextEditorSetters => ({
    abbreviationId: (id: string | undefined) => editor?.dispatchCommand(ABBREVIATION_CHANGE, id),
    blockLineHeight: value => editor?.dispatchCommand(BASE_PROPS_COMMAND.BLOCK_LINE_HEIGHT, value),
    blockType: type => editor?.dispatchCommand(BASE_PROPS_COMMAND.BLOCK_TYPE, type),
    bulletColor: (color: Models.BrandColorMap) => editor?.dispatchCommand(BULLET_COLOR_COMMAND, color),
    fontColor: brandColor => editor?.dispatchCommand(FONT_COMMAND.BRAND_COLOR, brandColor),
    fontFamily: (
      brandFont: Models.BrandFontMap,
      characterStyle: Models.CharacterStyleMap,
    ) => editor?.dispatchCommand(FONT_COMMAND.BRAND_FONT, { brandFont, characterStyle }),
    fontSize: (value: number) => editor?.dispatchCommand(FONT_COMMAND.SIZE, value),
    inlineStyle: (style: Constants.InlineStyle) => editor?.dispatchCommand(
      FONT_COMMAND.INLINE_STYLE,
      style,
    ),
    link: (value: string | undefined) => editor?.dispatchCommand(LINK_COMMAND, value),
    scriptStyle: (value: Constants.ScriptType) => editor?.dispatchCommand(BASE_PROPS_COMMAND.SCRIPT_STYLE, value),
    textNoWrap: () => editor?.dispatchCommand(BASE_PROPS_COMMAND.TEXT_NOWRAP, undefined),
  }), [editor]);


  const onChange: LexicalOnChange = useMemo(() => ({
    abbreviations: values => setProps(prev => ({
      ...prev,
      abbreviationId: values.abbreviationId,
      abbreviationTerm: values.abbreviationTerm,
    })),
    baseProps: (values: BaseProps) => setProps(prev => ({
      ...prev,
      blockLineHeight: values.blockLineHeight,
      blockType: values.blockType,
      scriptStyle: values.scriptStyle,
      textNoWrap: values.textNoWrap,
    })),
    bulletColor: value => setProps(prev => ({ ...prev, bulletColor: value })),
    font: values => setProps(prev => ({
      ...prev,
      fontColor: values.brandColor,
      fontFamily: values.brandFont,
      fontSize: values.size,
      inlineStyle: values.inlineStyle,
    })),
    link: values => setProps(prev => ({
      ...prev,
      link: values.link,
      linkApplicable: values.linkApplicable,
    })),
    textContent: value => setTextContent(value),
  }), [setProps]);

  const applyBrandStyleValues = useCallback(() => {
    // IN-PROGRESS: TBD
    throw new Error('TBD');
  }, []);

  // IN-PROGRESS let's move it to abbreviations plugin
  const chooseAbbreviation = useCallback((abbreviationId: string, abbreviationNumber: number | undefined) => {
    let results = false;
    ref.current?.update(() => {
      results = $selectAbbreviation(abbreviationId, abbreviationNumber);
    }, { discrete: true });

    return results;
  }, []);

  return {
    ref,
    textContent,
    onChange,
    editor,
    props,
    setters,
    applyBrandStyleValues,
    chooseAbbreviation,
  };
}
