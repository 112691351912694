
import React from 'react';

import * as ProjectPanelModels from 'containers/ProjectPanel/models';
import { useToggle as useBasicToggle } from 'hooks/useToggle';
import { ToggleOptions } from './models';

export const useToggle = ({
  toggleUpdatedDocumentsDisplaying,
  showUpdatedDocuments,
  entityType,
  isExpand = false,
  toggle: externalToggle,
}: ToggleOptions): {
    expanded: boolean;
    toggle: () => void;
  } => {
  let { on, toggle: basicToggle } = useBasicToggle(isExpand);

  if (externalToggle) {
    basicToggle = externalToggle;
    on = isExpand;
  }

  const toggle = (): void => {
    basicToggle();
    if (showUpdatedDocuments && toggleUpdatedDocumentsDisplaying) {
      toggleUpdatedDocumentsDisplaying(false, entityType);
    }
  };

  return {
    expanded: on,
    toggle,
  };
};

export const useScrollIntoView = <R extends Element = HTMLDivElement>(
  assetId: string,
  assetIdToScroll: string,
  setAssetIdToScroll: ProjectPanelModels.ActionCreator.SetAssetIdToScroll,
): { elementRef: React.RefObject<R> } => {
  const element = React.createRef<R>();

  React.useEffect(
    () => {
      if (assetIdToScroll && assetIdToScroll === assetId && element.current) {
        element.current.scrollIntoView();
        setAssetIdToScroll(null);
      }
    },
    [assetIdToScroll],
  );

  return {
    elementRef: element,
  };
};
