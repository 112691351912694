import React, { forwardRef } from 'react';

type AccessibleDivProps = Omit<React.HTMLAttributes<HTMLDivElement>, 'role'>;

/**
 * This is a wrapper over a `<div>` element that assigns a "button" role.
 * The purpuse of this approuch is bypass Sonar warnings about the misuse of the role attribute
 */
const AccessibleDiv = forwardRef<HTMLDivElement, AccessibleDivProps>(({
  children,
  ...props
}, ref) => <div role='button' ref={ref} {...props} >{children}</div>); //NOSONAR

AccessibleDiv.displayName = 'AccessibleDiv';

export default AccessibleDiv;
