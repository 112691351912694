import Editor from '@draft-js-plugins/editor';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import AnchorsList from 'components/ArtboardAssets/Text/components/AnchorsList';
import * as Constants from 'const';
import * as editorUtils from 'utils/editor';

type Props = {
  editorRef: React.RefObject<Editor>;
  editorState: Draft.EditorState;
  documentId: string;
  ssiPosition: Constants.SSIPosition;
};

export function SSIAnchors(props: Readonly<Props>): JSX.Element {
  const { editorRef, editorState, documentId, ssiPosition } = props;
  const editor = _.get(editorRef.current, 'editor.editor') as HTMLDivElement | null;

  const [anchors, setAnchors] = useState<JSX.Element | null>(null);

  useEffect(() => {
    if (!editor) {
      setAnchors(null);

      return;
    }

    const appliedAnchorKeys = editorUtils.getAppliedAnchorKeys(editorState);
    setAnchors(<AnchorsList
      editor={editor}
      appliedAnchorKeys={appliedAnchorKeys}
      documentId={documentId}
    />);
  }, [editor, editorState, document, ssiPosition]);

  return (
    <>{anchors}</>
  );
}
