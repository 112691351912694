import { undoRedoKeyBindings, applyEditorStateFontStylesForBrandStyles } from 'modules/draftjs';
import { useHandleKeyCommand } from 'modules/draftjs/hooks/useHandleKeyCommand';
import { KeyboardEvent, useCallback, useEffect, useRef } from 'react';
import * as Constants from 'const';
import { DefaultTextBrandStyle } from 'const/Styles';
import * as Models from 'models';
import { isDefaultBrandStyleAvailable } from 'utils/brandStyles';
import * as brandStylesUtils from 'utils/brandStyles';
import * as editorUtils from 'utils/editor';
import { eventEmitter, EMITTER_EVENTS } from 'utils/eventEmitter';
import { toImmutable } from 'utils/immutable';
import useStyles from '../hooks/useStyles';
import { TextProps } from '../models';
import { getColors, getFonts } from '../utils/brand';
import { applyBrandStyleValuesToEditorState, getEditorStateFromProps } from '../utils/editor';
import { orderChange, updateFonts } from '../utils/update';
import { BrandProps } from './useBrandProps';
import useCell from './useCell';
import useEditor from './useEditor';
import useUndo from './useUndo';

export default function useUpdate(
  props: TextProps,
  editorHook: ReturnType<typeof useEditor>,
  brandProps: BrandProps,
  stylesHook: ReturnType<typeof useStyles>,
  cellHook: ReturnType<typeof useCell>,
  undoHook: ReturnType<typeof useUndo>,
  storeText: () => void,
): void {

  const prevEditorStateRef = useRef(editorHook.editorState);
  const prevPropsRef = useRef(props);
  const prevBrandPropsRef = useRef(brandProps);
  let currentEditorState = editorHook.editorState;

  // repeats setBrandStyle functionality but with custom editorState and some additions
  const toggleBrandStyle = (
    brandStyle: Models.BrandStyleMap, // to remove this parameter logic for DefaultTextBrandStyle should be updated
    editorState: Draft.EditorState,
    needToToggleAutoHeight,
    relation: Models.LayeredRegularRelationMap<Models.TextRelationStyles>,
    activeLayer: Constants.Layer,
  ): Draft.EditorState => {
    const { projectType } = props;
    const { colors, fonts } = brandProps;
    const textStyles = brandStylesUtils.getTextStylesFromBrandStyle(brandStyle, colors, fonts);

    // same as EditorHook[applyBrandStyleValues] but uses custom editorState and returns it
    const newEditorState = applyBrandStyleValuesToEditorState(editorState, projectType, textStyles, brandProps);
    editorHook.setEditorStateAndOperations(newEditorState);

    let brandStyleChanged = false;
    if (needToToggleAutoHeight) {
      if (!cellHook.props.isAutoFitContent) {
        cellHook.toggleAutoFitContent();
      }
    } else if (!cellHook.props.isAutoFitContent) {
      brandStyleChanged = true;
    }

    stylesHook.applyBrandStyle(brandStyle, textStyles, brandStyleChanged);
    stylesHook.ensureStylesToBeKept(relation, activeLayer, brandProps);

    return newEditorState;
  };


  const handleKeyCommand = useHandleKeyCommand(undoHook.fillUndoStackIfEmpty, undoHook.undo, undoHook.redo);

  const undoRedoGlobalHandler = useCallback((e: KeyboardEvent): void => {
    handleKeyCommand(undoRedoKeyBindings(e));
  }, [handleKeyCommand]);

  useEffect(() => {
    return () => {
      eventEmitter.removeListener(EMITTER_EVENTS.BODY_KEYDOWN, undoRedoGlobalHandler);
    };
  }, [undoRedoGlobalHandler]);

  const shouldReturnFocusToEditorRef = useRef(false);
  useEffect(() => {
    if (shouldReturnFocusToEditorRef.current) {
      editorHook.returnFocusToEditor();
      shouldReturnFocusToEditorRef.current = false;
    }
  }, [editorHook.editorState]);

  useEffect(() => {
    const { current } = prevPropsRef;
    const { editMode, isAutoFitContent } = props;

    const isAutoFitContentChanged = isAutoFitContent && current.isAutoFitContent !== isAutoFitContent;
    const isEditModeChanged = current.editMode !== editMode;

    if (isEditModeChanged || isAutoFitContentChanged) {
      cellHook.resetHeight();
    }
  }, [props.isAutoFitContent, props.editMode]);

  useEffect(() => {
    orderChange(props, brandProps, editorHook, prevPropsRef, currentEditorState);
  }, [props.editMode, props.document]);

  useEffect(() => {
    const { colors, fonts } = brandProps;
    const { projectType } = props;
    // TODO: check whether we really need setFullSelection or the problem was in other place and was already fixed
    // HACK to trigger Draft Editor re-render when new color styles was provided
    if (
      (!colors.equals(getColors(prevPropsRef.current)) || !fonts.equals(getFonts(prevPropsRef.current)))
      && currentEditorState === editorHook.editorState
    ) {
      currentEditorState = getEditorStateFromProps(props, brandProps);
      currentEditorState = applyEditorStateFontStylesForBrandStyles(currentEditorState, projectType, fonts);
      currentEditorState = editorUtils.setFullSelection(currentEditorState);
      editorHook.setEditorStateAndOperations(currentEditorState);
      shouldReturnFocusToEditorRef.current = true;
    }
  }, [brandProps.colors, brandProps.fonts, props.projectType]);

  useEffect(()=> {
    const { relation, activeLayer } = props;
    const { brandStyles } = brandProps;
    // skip run on mount
    if (prevBrandPropsRef.current.brandStyles === brandStyles) {
      return;
    }

    const styles = relation.getIn(['styles', activeLayer]);
    const brandStyleId = styles?.get('brandStyleId');
    const brandStyleChanged = !cellHook.props.isAutoFitContent || styles?.get('brandStyleChanged');

    if (!brandStyles
      || !brandStyleId
      || brandStyleChanged
      || brandStyles.equals(prevBrandPropsRef.current.brandStyles)
    ) {
      return;
    }

    // ??? not trivial approach
    currentEditorState = toggleBrandStyle(
      brandStyles.get(brandStyleId),
      currentEditorState,
      true,
      relation,
      activeLayer,
    );
  }, [brandProps.brandStyles]);


  useEffect(() => {
    // isAutoFitContent of cellHook prevents us from moving this logic to useStyles hook
    // TODO: may we remove the dependency on isAutoFitContent?

    // skip run on mount
    if (prevPropsRef.current === props) {
      return;
    }
    const { relation, activeLayer } = props;

    // we check that the styles is changed without autofit content as in some cases it causes unnecessary triggers of state sync
    const path = ['styles', activeLayer, 'isAutoFitContent'];
    const preparedCurrentStyles = relation.hasIn(path) ? relation.deleteIn(path) : relation;
    const preparedPrevStyles = prevPropsRef.current.relation.hasIn(path)
      ? prevPropsRef.current.relation.deleteIn(path)
      : prevPropsRef.current.relation;
    if (preparedCurrentStyles.equals(preparedPrevStyles)) {
      return;
    }
    const source = props.relation.getIn(['styles', props.activeLayer]) || toImmutable({});

    const brandStyleChanged = !cellHook.props.isAutoFitContent || source.get('brandStyleChanged');
    stylesHook.resetStyles(
      source,
      brandProps.brandStyles,
      source.get('brandStyleId') || DefaultTextBrandStyle,
      brandStyleChanged,
      brandProps.colors,
    );
  }, [
    props.relation.getIn(['styles', props.activeLayer]), // can be undefined
    props.relation.get('id'),
    props.activeLayer,
  ]);

  useEffect(() => {
    const { fonts } = brandProps;
    updateFonts(fonts, editorHook);
  }, [currentEditorState]);

  useEffect(()=> {
    prevEditorStateRef.current = editorHook.editorState;
  }, [editorHook.editorState]);

  useEffect((): void => {
    const { document, relation, editMode, activeLayer } = props;
    const { brandStyles, colors } = brandProps;
    const styles = relation.getIn(['styles', activeLayer]);
    const brandStyleId = styles && styles.get('brandStyleId');

    if (prevPropsRef.current.editMode !== editMode) {
      if (editMode) {
        editorHook.returnFocusToEditor();
        // useDefaultStyles
        const noComponentAssigned = !relation.getIn(['documentId', activeLayer]) || !document;
        // NOTE: brand style and font color must be ignored if there is no document assigned
        if (!brandStyleId && noComponentAssigned) {
          if (isDefaultBrandStyleAvailable(brandStyles, props.sectionStyles)) {
            toggleBrandStyle(
              brandStyles.get(Constants.Styles.DefaultTextBrandStyle),
              currentEditorState,
              false,
              relation,
              activeLayer,
            );
          } else {
            editorHook.setters.fontColor(
              editorUtils.getBrandColor(colors, Constants.DefaultCustomStyle.FONT_COLOR),
              currentEditorState,
            );
            stylesHook.ensureStylesToBeKept(relation, activeLayer, brandProps);
          }
        }
        editorHook.setOperations([]);
        cellHook.resetCellWidhtAndHeightChange();
        props.saveAppState();
        eventEmitter.addListener(EMITTER_EVENTS.BODY_KEYDOWN, undoRedoGlobalHandler);
      } else {
        storeText();
        if (undoHook.isUndoDisabled) {
          props.cancel();
        }
        undoHook.reset();
        eventEmitter.removeListener(EMITTER_EVENTS.BODY_KEYDOWN, undoRedoGlobalHandler);
      }
    }
  });

  useEffect(() => {
    prevPropsRef.current = props;
    prevBrandPropsRef.current = brandProps;
  });
}
