import classNames from 'classnames';
import * as React from 'react';
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import { useSelector, useDispatch } from 'react-redux';
import { IMap } from 'typings/DeepIMap';
import Icon from 'components/Icon';
import { IconType } from 'components/Icon/constants';
import { ASSEMBLER_HELP, MARCAPS_SUPPORT, MODULAR_CONTENT_EMAIL } from 'const';
import { hideModal as hideModalAction } from 'containers/ModalManager/actions';
import { modalWindowOptions } from 'containers/ModalManager/selectors';
import { ExtendedAssemblerError, NotificationWindowOptions } from 'models';
import { intlGet } from 'utils/intlGet';
import { NotificationProps } from '../Notification/models';
import CopyErrorIcon from './CopyClick';
import styles from './styles.module.scss';

type OptionsInput = NotificationWindowOptions & { error: ExtendedAssemblerError };

const NotificationAccessError: React.FunctionComponent<NotificationProps> = (props) => {
  const dispatch = useDispatch();

  const { modalWindowId } = props;
  const options: IMap<OptionsInput> = useSelector(modalWindowOptions(modalWindowId));

  const {
    title,
    message,
    error,
    disableClickOutside, // artboard Mounted
  } = options.toJS() as OptionsInput;

  const onCloseClick = (): void => {
    dispatch(hideModalAction(modalWindowId));
  };

  const listDocuments = error?.documentIds?.map((documentId) => {
    return (<li key={documentId}>
      <a target='_blank' href={`${error.veevaDocInfoUrl as string}${documentId}`}>
        {documentId}
      </a>
    </li>);
  });

  const [listIsExpanded, setListIsExpanded] = useState(false);

  return (
    <div className={styles.NotificationAccessError}>
      <div className={classNames(styles.title, 'text-center')}>{title}</div>

      <div className='mt-4'>{message}</div>
      <div className='mt-4 d-flex justify-content-between'>
        <div>
          {intlGet('Notification.PromoMatsError.AccessError', 'DocumentIdMessage')}:<br/>
          <div>
            <ol className={classNames('mt-4', styles.listOfDocuments)}>
              {listIsExpanded ? listDocuments : null}
            </ol>
          </div>
        </div>

        {
          <Icon
            type={listIsExpanded ? IconType.CHEVRON_UP : IconType.CHEVRON_DOWN}
            size="sm" color="grey"
            onClick={(): void => setListIsExpanded(!listIsExpanded)}
          />
        }
      </div>

      <div className='mt-1'>
      Recommendations:
        <ul>
          <li>try contacting the document owner and request access to the document</li>
          <li>
              visit the <a target='_blank' href={ASSEMBLER_HELP}>Content Capabilities Help Site</a>.
              To get the credentials to access the site send an email to <a href={`mailto:${MODULAR_CONTENT_EMAIL}`}>{MODULAR_CONTENT_EMAIL}</a>
          </li>
          <li>report <a target='_blank' href={MARCAPS_SUPPORT}>MARCAPS ticket</a></li>
        </ul>
      </div>

      <div className='d-flex mt-4' style={{ height: 50 }}>
        <CopyErrorIcon asmolError={error} />
      </div>

      {
        disableClickOutside ? null : <div className='text-center mt-4'>
          <Button className={styles.button} onClick={onCloseClick}>{intlGet('NotificationWindow.Button', 'Close')}</Button>
        </div>
      }
    </div>
  );
};

export default NotificationAccessError;
