import Draft from 'draft-js';
import Immutable from 'immutable';
import _ from 'lodash';
import { createAbbreviationsListCellSchema } from 'modules/Abbreviations/converter';
import {
  AbbreviationsListSchemaCell,
  AbbreviationsMapping,
  AbbreviationsListRelationMap,
} from 'modules/Abbreviations/types';
import { abbreviationsListsFromContext } from 'modules/Abbreviations/utils/abbreviationsListFromContext';
import * as BrandDefinition from 'modules/BrandDefinition';
import { IMap } from 'typings/DeepIMap';
import * as Constants from 'const';
import { ProjectType } from 'const';
import { DefaultTextBrandStyle } from 'const/Styles';
import * as DocumentsModels from 'containers/Documents/models';
import * as Models from 'models';
import { allowImageMobileSettings } from 'utils/allowImageMobileSettings';
import { getCellBrandStylesForExport, getDesktopBrandStyles, getInlineStylesForExport, getTextStylesFromBrandStyle } from 'utils/brandStyles';
import {
  borderFromSource,
  colorToSource,
  colorToTint,
} from 'utils/converters';
import * as editorUtils from 'utils/editor';
import { isImage, isSpacerRelation } from 'utils/entityType';
import { getDefaultPreviewOption } from 'utils/getDefaultPreviewOption';
import { getExtraHeight } from 'utils/getExtraHeight';
import { getExtraWidth } from 'utils/getExtraWidth';
import { getIntegerFromStyle } from 'utils/getIntegerFromStyle';
import { getOrderedSections } from 'utils/getOrderedSections';
import { getReferencesAsString } from 'utils/getReferencesAsString';
import { getScreenPosition } from 'utils/getScreenPosition';
import { getScreenSectionsByName } from 'utils/getScreenSectionsByName';
import { getScreenWidth } from 'utils/getScreenWidth';
import { toImmutable } from 'utils/immutable';
import { fitLayoutIntoSection } from 'utils/layouts/fitLayoutIntoSection';
import { fitLayoutIntoSSIElement } from 'utils/layouts/fitLayoutIntoSSIElement';
import { getFlattenedLayouts } from 'utils/layouts/getFlattenedLayouts';
import { getRowWidth } from 'utils/layouts/getLayoutWidth';
import { isGroupLayout } from 'utils/layouts/isGroupLayout';
import { getFlattenedRelations } from 'utils/relations/getFlattenedRelations';
import { isColumnRelation } from 'utils/relations/isColumnRelation';
import { isRowRelation } from 'utils/relations/isRowRelation';
import { removeScriptTag } from 'utils/removeScriptTag';
import { isReusableLayout } from 'utils/reusableLayouts/isReusableLayout';
import { areThereHorizontalNeighbors } from 'utils/rowsHeight/areThereHorizontalNeighbors';
import { getScreenHeight } from 'utils/screens/getScreenHeight';
import {
  boxPropertyToString,
  getBackgroundImage,
  getBorderCSSProperties,
  getPadding,
  getBorderRadius,
} from 'utils/styles';
import { getSSISize } from 'utils/styles/getSSISize';
import { sum } from 'utils/sum';
import { toPx } from 'utils/toPx';
import { CellEntityType, LayoutType, NEW_LINE_DELIMITER, NEW_LINE_REG_EXP, SegmentType } from './constants';
import * as factories from './factories';
import { ArtboardsJson, CreateArtboardsJsonOptions, Schemas, ScreenOptions } from './models';
import { addInvisibleJoinersToUrls } from './utils/addInvisibleJoinersToUrls';
import { getAlignment } from './utils/getAlignment';
import { getBackgroundColor } from './utils/getBackgroundColor';
import { getBackgroundGradient } from './utils/getBackgroundGradient';
import { getCellHeight } from './utils/getCellHeight';
import { getImageMobileSettings } from './utils/getImageMobileSettings';
import { getImageWidth } from './utils/getImageWidth';
import { getLayoutStyle } from './utils/getLayoutStyle';
import { getLinks } from './utils/getLinks';
import { getListIndent } from './utils/getListIndent';
import { getParagraphAlign } from './utils/getParagraphAlign';
import { getReferenceIndent } from './utils/getReferenceIndent';
import { getSegmentType } from './utils/getSegmentType';

export class ArtboardConverter {
  private artboardBackgroundFromMasterScreen: string;

  private artboardsContext: Models.ArtboardsContextMap;

  private surfaces: Models.ScreensOrderedMap;

  private artboards: Models.ArtboardsMap;

  private brandStyles: Models.BrandStylesMap;

  private brandStylesByRelationId: DeepIMap<Record<string, Models.BrandStylesRecord>>;

  private cellsHeight: Models.CellsHeightMap;

  private previewOptionsByScreenId: DeepIMap<Record<string, Models.PreviewOptions>>;

  private screenDefinitions: Models.MasterScreen.ScreenDefinitionsMap;

  private sections: Models.SectionsMap;

  private layouts: Models.CombinedLayoutsMap;

  private relations: Models.RelationsMap;

  private documents: Models.CombinedDocumentsMap;

  private colors: Models.BrandColorsList;

  private colorsByLayoutId: Models.BrandColorsMap;

  private colorsByRelationId: Models.BrandColorsMap;

  private fontsByRelationId: Models.BrandFontsMap;

  private referenceCitationsByReferenceElements: DocumentsModels.ReferenceCitationsByReferenceElementsMap;

  private citationsOrderByDocuments: DocumentsModels.ReferenceCitationsOrderByDocumentsMap;

  private sectionsWidthByScreen: Models.MasterScreen.SectionsWidthByScreenMap;

  private sectionsHeightByScreen: Models.MasterScreen.SectionsHeightByScreenMap;

  private brand: string;

  private projectType: Constants.ProjectType;

  private currentArtboardId: string;

  private currentSectionId: string;

  private isSSIMode: boolean;

  private options: CreateArtboardsJsonOptions;

  private rootDocument: Models.RootDocumentMap;

  constructor(artboardsContext: Models.ArtboardsContextMap, rootDocument: Models.RootDocumentMap) {
    this.artboardsContext = artboardsContext;
    this.artboardBackgroundFromMasterScreen = artboardsContext.get('artboardBackgroundFromMasterScreen');
    this.surfaces = artboardsContext.get('surfaces');
    this.artboards = artboardsContext.get('artboards');
    this.brandStyles = artboardsContext.get('brandStyles');
    this.brandStylesByRelationId = artboardsContext.get('brandStylesByRelationId');
    this.cellsHeight = artboardsContext.get('cellsHeight');
    this.previewOptionsByScreenId = artboardsContext.get('previewOptionsByScreenId');
    this.screenDefinitions = artboardsContext.get('screenDefinitions');
    this.sections = artboardsContext.get('sections');
    this.layouts = artboardsContext.get('layouts');
    this.relations = artboardsContext.get('relations');
    this.documents = artboardsContext.get('documents');
    this.colors = artboardsContext.get('colors');
    this.colorsByLayoutId = artboardsContext.get('colorsByLayoutId');
    this.colorsByRelationId = artboardsContext.get('colorsByRelationId');
    this.fontsByRelationId = artboardsContext.get('fontsByRelationId');
    this.referenceCitationsByReferenceElements = artboardsContext.get('citationsByReferenceElements');
    this.citationsOrderByDocuments = artboardsContext.get('citationsOrderByDocuments');
    this.sectionsWidthByScreen = artboardsContext.get('sectionsWidthByScreen');
    this.sectionsHeightByScreen = artboardsContext.get('sectionsHeightByScreen');
    this.brand = artboardsContext.get('brand');
    this.projectType = artboardsContext.get('projectType');
    this.currentArtboardId = null;
    this.currentSectionId = null;
    this.isSSIMode = false;
    this.rootDocument = rootDocument;
  }

  public createOutput(options?: CreateArtboardsJsonOptions): ArtboardsJson {
    this.setOptions(options);

    const styles: Models.BrandStyles = this.brandStyles.valueSeq().toJS();
    const artboards = this.mapArtboards();

    return {
      brandColors: this.colors.toJS() as Models.BrandColor[],
      styles,
      artboards,
      brand: this.brand,
      projectType: this.projectType,
    } as ArtboardsJson;
  }

  private mapArtboards(): Schemas.Artboard[] {
    return this.options.screenIds
      .map(screenId => this.surfaces.get(screenId))
      .filter(surface => !!surface.get('screenDefinitionId'))
      .map((surface: Models.ScreenMap): Schemas.Artboard => {
        const artboardId = surface.get('artboardId');
        const previewOptions = this.previewOptionsByScreenId.get(surface.get('id')).toJS() as Models.PreviewOptions;
        this.setCurrentArtboardId(artboardId);
        const {
          layoutIds,
          artboardWidth,
          allowArtboardHeight,
          allowArtboardBackground,
          isLayoutPreview,
        } = this.getCurrentScreenOptions();
        const {
          allowSSI,
          areScreensResizable,
        } = Constants.ProjectsConfig[this.projectType];

        const artboard = this.artboards.get(artboardId);
        const artboardStyles: IMap<Models.ArtboardStyles> = artboard.get('styles');
        const screenDefinitionId = surface.get('screenDefinitionId');
        const artboardLayoutIds = toImmutable(layoutIds) || artboard.get('layoutIds');
        const screenDefinition = this.screenDefinitions.get(screenDefinitionId);

        const artboardLayouts = artboardLayoutIds.reduce(
          (layouts, id) => layouts.push(this.layouts.get(id)),
          Immutable.List() as Immutable.List<Models.CombinedLayoutMap>,
        );

        const artboardSectionIds = artboardLayouts.map(layout => layout.get('section'));
        const artboardSectionsByName = getScreenSectionsByName(this.sections, artboardSectionIds);
        const artboardSections = getOrderedSections(artboardSectionsByName, screenDefinition.get('sections')).filter(Boolean);

        const backgroundImage = this.convertBackgroundImage(
          artboardStyles.get('backgroundImage'),
          allowArtboardBackground,
        );
        const backgroundColor = allowArtboardBackground
          ? getBackgroundColor(
            this.projectType,
            this.colors,
            artboardStyles.get('backgroundColor'),
            artboardStyles.get('backgroundColorTint'),
          )
          : null;
        const outsideBackgroundColor = allowArtboardBackground
          ? getBackgroundColor(
            this.projectType,
            this.colors,
            artboardStyles.get('outsideBackgroundColor'),
            artboardStyles.get('outsideBackgroundColorTint'),
            undefined,
            this.artboardBackgroundFromMasterScreen,
          )
          : null;

        const width = toPx(artboardWidth || getScreenWidth(screenDefinition, artboard, this.projectType));
        const previewOption = areScreensResizable
          ? previewOptions[0]
          : getDefaultPreviewOption(previewOptions, this.projectType);
        const thumbnailWidth = allowSSI ? width : _.get(previewOption, 'width');

        return {
          id: artboardId,
          screenName: surface.get('name'),
          screenDefinitionDocumentId: parseInt(screenDefinitionId, 10),
          screenType: screenDefinition.get('screenType'),
          sections: this.mapSections(
            artboardSections,
            artboardLayoutIds,
            this.sectionsHeightByScreen.get(surface.get('id')),
          ),
          position: getScreenPosition(surface, this.surfaces),
          ssi: this.createSSISchema(surface) || undefined,
          width,
          height: allowArtboardHeight
            ? toPx(getScreenHeight(screenDefinition, artboard, this.projectType))
            : undefined,
          previewOptions,
          thumbnailWidth,
          ...backgroundImage,
          backgroundColor,
          backgroundGradient: null,
          outsideBackgroundColor,
          isLayoutPreview,
        };
      });
  }

  private createSSISchema(targetSurface: Models.ScreenMap): Schemas.SSI {
    const { allowSSI } = this.getCurrentScreenOptions();
    const targetArtboardId = targetSurface.get('artboardId');
    const ssi = this.artboards.getIn([targetArtboardId, 'ssi']);

    if (!allowSSI || !ssi || !ssi.get('source')) {
      return null;
    }

    const ssiSource = ssi.get('source');
    const ssiTargetSection = ssi.get('section');
    const targetSurfaceId = targetSurface.get('id');
    const targetSurfaceSectionsWidth = this.sectionsWidthByScreen.get(targetSurfaceId);
    const ssiSourceSectionName = ssiSource.get('section');
    const ssiSourceScreenId = ssiSource.get('screen');

    const sourceSurface = this.surfaces.find(surface => ssiSourceScreenId === surface.get('id'));
    const sourceArtboardId = sourceSurface.get('artboardId');
    const layoutIds = this.artboards.getIn([sourceArtboardId, 'layoutIds']);
    const sourceArtboardLayouts = getFlattenedLayouts(this.artboards.get(sourceArtboardId), this.layouts);
    const sourceSection = sourceArtboardLayouts
      .map(layout => layout.get('section'))
      .map(sectionId => this.sections.get(sectionId))
      .find(section => section.get('name') === ssiSourceSectionName);

    const converter = new ArtboardConverter(this.artboardsContext, this.rootDocument);
    converter.setCurrentArtboardId(targetArtboardId);
    converter.activateSSIMode();

    const ssiLayouts = converter
      .mapSectionLayouts(layoutIds, sourceSection.get('id'))
      .map((layout) => {
        if (layout.height) {
          // we shouldn't constraint a scrollable layout height within the SSI element
          delete layout.height;
        }

        return layout;
      });
    const targetSectionWidth = targetSurfaceSectionsWidth.get(ssiTargetSection);
    const targetSectionHeight = this.sectionsHeightByScreen.getIn([targetSurfaceId, ssiTargetSection]);
    const ssiSize = getSSISize(ssi, targetSectionWidth, targetSectionHeight) as { width?: string; height: string };

    return factories.createSSI({
      position: ssi.get('position'),
      section: ssiTargetSection,
      layouts: ssiLayouts,
      sourceArtboardId,
      sourceSection: ssiSourceSectionName,
      ...ssiSize,
    });
  }

  private mapSections(
    sections: Immutable.List<Models.SectionMap>,
    layoutIds: Immutable.List<string>,
    sectionsHeight: Models.MasterScreen.SectionsHeightMap,
  ): Schemas.Section[] {
    return sections
      .map((section): Schemas.Section => {
        const sectionId = section.get('id');
        this.setCurrentSectionId(sectionId);
        const sectionName = section.get('name');
        const sectionStyles = section.get('styles');
        const sectionHeight = sectionsHeight.get(sectionName);
        const { allowSectionBackground, allowSectionHeight } = this.getCurrentScreenOptions();
        const backgroundColor = allowSectionBackground
          ? getBackgroundColor(
            this.projectType,
            this.colors,
            sectionStyles.get('backgroundColor'),
            sectionStyles.get('backgroundColorTint'),
            sectionStyles.get('backgroundColorOpacity'),
          )
          : null;
        const backgroundGradient = allowSectionBackground
          ? getBackgroundGradient(
            this.projectType,
            this.colors,
            sectionStyles.get('backgroundGradient'),
          )
          : null;

        return {
          layouts: this.mapSectionLayouts(layoutIds, sectionId),
          height: allowSectionHeight ? toPx(sectionHeight) : undefined,
          type: sectionName,
          backgroundColor,
          backgroundGradient,
          ...this.convertBackgroundImage(section.getIn(['styles', 'backgroundImage']), allowSectionBackground),
        };
      }).toJS();
  }

  private mapSectionLayouts(layoutIds: Immutable.List<string>, sectionId: string): Schemas.CombinedLayout[] {
    const { allowScrollableLayout } = Constants.ProjectsConfig[this.projectType];

    if (allowScrollableLayout) {
      return layoutIds
        .map(layoutId => this.layouts.get(layoutId))
        .filter(layout => layout.get('section') === sectionId)
        .map(layout => isGroupLayout(layout) ? this.createGroupLayoutSchema(layout) : this.createLayoutSchema(layout))
        .toArray();
    }

    return getFlattenedLayouts(Immutable.fromJS({ layoutIds }), this.layouts, true)
      .filter(layout => layout.get('section') === sectionId)
      .map(layout => this.createLayoutSchema(layout))
      .valueSeq()
      .toArray();
  }

  private createGroupLayoutSchema(layout: Models.GroupLayoutMap): Schemas.GroupLayout {
    const height = layout.getIn(['styles', 'height']);
    const layoutIds = layout.get('layoutIds');

    return {
      layouts: layoutIds.map(layoutId => this.createLayoutSchema(this.layouts.get(layoutId) as Models.LayoutMap)).toArray(),
      type: LayoutType.GROUPED,
      height: toPx(height),
    };
  }

  private createLayoutSchema(layout: Models.LayoutMap): Schemas.Layout {
    const ssi = this.artboards.getIn([this.getCurrentArtboardId(), 'ssi']);
    this.relations = !this.isSSIMode
      ?
      fitLayoutIntoSection(
        layout,
        this.relations,
        this.getCurrentSectionWidth(),
      )
      :
      fitLayoutIntoSSIElement(
        layout,
        this.sectionsWidthByScreen.get(this.getCurrentScreenId()),
        ssi,
        this.relations,
      );

    const layoutId = layout.get('id');
    const styles = layout.get('styles');
    const colors = this.colorsByLayoutId.get(layoutId);
    const responsive = styles.get('responsive');
    const scrollable = styles.get('scrollable');
    const backgroundColor = getBackgroundColor(
      this.projectType,
      colors,
      styles.get('backgroundColor'),
      styles.get('backgroundColorTint'),
      styles.get('backgroundColorOpacity'),
    );
    const backgroundGradient = getBackgroundGradient(
      this.projectType,
      colors,
      styles.get('backgroundGradient'),
    );
    const padding = getPadding(styles.get('padding'));
    const borderRadius = getBorderRadius(styles.get('borderRadius'));
    const border = getBorderCSSProperties(borderFromSource(colors, layout));
    const screenSectionsWidth = this.sectionsWidthByScreen.get(this.getCurrentScreenId());
    const layoutRelation = this.relations.get(layout.get('relationId')) as Models.RowRelationMap | Models.ColumnRelationMap;
    const layoutRelations = getFlattenedRelations(layoutRelation, this.relations);
    const hasTable = layoutRelations.some(relation => isRowRelation(relation) && relation.get('relationIds').size > 1);

    const sectionId = layout.get('section');
    const sectionName = this.sections.getIn([sectionId, 'name']);
    const layoutContentBoxHeight = isRowRelation(layoutRelation) ? this.getRowHeight(layoutRelation) : this.getColumnHight(layoutRelation);
    const layoutBorderBoxHeight = layoutContentBoxHeight + getExtraHeight(styles);
    const sectionWidth = screenSectionsWidth.get(sectionName);
    const layoutBorderBoxWidth = this.isSSIMode ? sectionWidth * ssi.get('scale') : sectionWidth;
    const layoutContentBoxWidth = layoutBorderBoxWidth - getExtraWidth(styles);
    const isReusable = isReusableLayout(layout, this.documents);
    const height = scrollable ? toPx(styles.get('height') - getExtraHeight(styles)) : toPx(layoutBorderBoxHeight);

    return {
      ...factories.createBorder(border as Partial<Schemas.Border>),
      ...factories.createBoxModel({ ...padding } as Schemas.BoxModel),
      borderRadius: String(borderRadius.borderRadius),
      backgroundColor,
      backgroundGradient,
      ...this.convertBackgroundImage(styles.get('backgroundImage')),
      style: getLayoutStyle(responsive),
      scrollable,
      height,
      type: LayoutType.REGULAR,
      hasTable,
      rows: isRowRelation(layoutRelation)
        ? [this.createRow(layoutRelation, layoutBorderBoxHeight, layoutBorderBoxWidth, isReusable)]
        : (layoutRelation as Models.ColumnRelationMap).get('relationIds').map((id) => {
          const relation = this.relations.get(id) as Models.RowRelationMap | Models.RegularRelationMap;

          return isRowRelation(relation)
            ? this.createRow(relation, layoutBorderBoxHeight, layoutBorderBoxWidth, isReusable)
            : this.createCellSchema(relation, layoutContentBoxWidth, layoutBorderBoxHeight, layoutBorderBoxWidth, isReusable);
        }).filter(Boolean).toJS(),
    };
  }

  private getRowHeight(relation: Models.RowRelationMap): number {
    return _.max(relation.get('relationIds').map((relationId) => {
      const relation = this.relations.get(relationId);

      return isColumnRelation(relation)
        ? this.getColumnHight(relation)
        : this.getCellHeight(relation as Models.RegularRelationMap);
    }).toArray());
  }

  private getColumnHight(relation: Models.ColumnRelationMap): number {
    return sum(relation.get('relationIds').map((relationId) => {
      const relation = this.relations.get(relationId);

      return isRowRelation(relation)
        ? this.getRowHeight(relation)
        : this.getCellHeight(relation);
    }));
  }

  private getCellHeight(relation: Models.RegularRelationMap): number {
    return isSpacerRelation(relation)
      ? relation.getIn(['styles', 'height'])
      : this.cellsHeight.get(`${this.getCurrentArtboardId()}:${relation.get('id')}`);
  }

  private createRow(relation: Models.RowRelationMap, layoutHeight: number, parentWidth: number, isReusable: boolean): Schemas.Row {
    const relationIds = relation.get('relationIds');
    const columnsWidth = relation.getIn(['styles', 'columnsWidth']);
    const rowHeight = this.getRowHeight(relation);
    const rowWidth = getRowWidth(relation);

    const rowHeightPercent = `${_.round(rowHeight / layoutHeight * 100)}%`;
    const rowWidthPercent = `${_.round(rowWidth / parentWidth * 100)}%`;

    const isNested = relationIds.size > 1;

    return {
      columns: relationIds.map((relationId, index) => {
        const columnWidth = columnsWidth.get(index);
        const relation = this.relations.get(relationId) as Models.ColumnRelationMap | Models.RegularRelationMap;

        return isColumnRelation(relation)
          ? this.createColumn(relation, columnWidth, layoutHeight, rowWidth, isReusable, isNested)
          : this.createCellSchema(relation, columnWidth, rowHeight, rowWidth, isReusable, isNested);
      }).filter(Boolean).toJS(),
      height: toPx(rowHeight),
      heightPercent: rowHeightPercent,
      width: toPx(rowWidth),
      widthPercent: rowWidthPercent,
    };
  }

  private createColumn(
    relation: Models.ColumnRelationMap,
    columnWidth: number,
    layoutHeight: number,
    parentWidth: number,
    isReusable: boolean,
    isNested = false,
  ): Schemas.Column {
    const relationIds = relation.get('relationIds');
    const columnHeight = this.getColumnHight(relation);

    const columnHeightPercent = `${_.round(columnHeight / layoutHeight * 100)}%`;
    const columnWidthPercent = `${_.round(columnWidth / parentWidth * 100)}%`;

    return {
      ...factories.createBoxModel(),
      rows: relationIds.map((row) => {
        const relation = this.relations.get(row) as Models.RowRelationMap | Models.RegularRelationMap;

        return isRowRelation(relation)
          ? this.createRow(relation, layoutHeight, columnWidth, isReusable)
          : this.createCellSchema(relation, columnWidth, columnHeight, columnWidth, isReusable, isNested);
      }).filter(Boolean).toJS(),
      height: toPx(columnHeight),
      heightPercent: columnHeightPercent,
      width: toPx(columnWidth),
      widthPercent: columnWidthPercent,
    };
  }

  private createCellSchema(
    relation: Models.RegularRelationMap<Models.CombinedRelationStyles>,
    borderBoxCellWidth: number,
    parentHeight: number,
    parentWidth: number,
    isReusable: boolean,
    isNested = false,
  ): Schemas.CombinedCell {
    const entityType = relation.get('entityType');
    if (entityType === Constants.EntityType.SPACER) {
      return this.convertSpacer(relation as Models.RegularRelationMap<Models.SpacerRelationStyles>);
    }

    const documentId = relation.get('documentId');
    const relationId = relation.get('id');
    const colors = this.colorsByRelationId.get(relationId);
    let styles = relation.get('styles') as DeepIMap<Models.CombinedAssetRelationStyles>;

    let padding = getPadding(styles.get('padding'));
    const borderRadius = getBorderRadius(styles.get('borderRadius'));
    const border = getBorderCSSProperties(borderFromSource(colors, relation));
    let backgroundColor = getBackgroundColor(
      this.projectType,
      colors,
      styles.get('backgroundColor'),
      styles.get('backgroundColorTint'),
      styles.get('backgroundColorOpacity'),
    );
    let backgroundGradient = getBackgroundGradient(
      this.projectType,
      colors,
      styles.get('backgroundGradient'),
    );
    const alignment = getAlignment(styles.get('alignment'));
    const backgroundImage = this.convertBackgroundImage(styles.get('backgroundImage'));

    // revise brand style logic (the same in Text Component components/ArtbardAssets/Text)
    if (entityType === Constants.EntityType.TEXT) {
      const brandStyleId = (styles as DeepIMap<Models.TextRelationStyles>).get('brandStyleId');
      const brandStyleChanged = (styles as DeepIMap<Models.TextRelationStyles>).get('brandStyleChanged');

      if (!isReusable && !brandStyleChanged) {
        const brandStyle = this.brandStyles.get(brandStyleId) || this.brandStyles.get(DefaultTextBrandStyle);
        const cellStyles = getCellBrandStylesForExport(brandStyle, this.colors);
        backgroundColor = getBackgroundColor(
          this.projectType,
          this.colors,
          colorToSource(cellStyles.backgroundColor),
          colorToTint(cellStyles.backgroundColor),
          styles.get('backgroundColorOpacity'),
        );
        backgroundGradient = null;
        padding = getPadding(cellStyles.padding);
        alignment.valign = cellStyles.verticalAlignment;

        styles = styles.setIn(['padding'], cellStyles.padding);
      }
    }

    const extraHeight = getExtraHeight(styles);
    const extraWidth = getExtraWidth(styles);
    const contentBoxCellHeight = getCellHeight(this.getCurrentArtboardId(), relation.setIn(['styles'], styles), this.cellsHeight);
    const borderBoxCellHeight = _.parseInt(contentBoxCellHeight, 10) + extraHeight;
    const cellHeightPercent = `${_.round(borderBoxCellHeight / parentHeight * 100)}%`;
    const contentBoxCellWidth = toPx(borderBoxCellWidth - extraWidth);
    const cellWidthPercent = `${_.round(borderBoxCellWidth / parentWidth * 100)}%`;

    // TODO: move baseSchema generation inside specialized functions ???
    const baseCellSchema: Schemas.Cell = {
      ...factories.createAlignment(alignment),
      backgroundColor,
      backgroundGradient,
      ...backgroundImage,
      ...factories.createBorder(border),
      borderRadius: String(borderRadius.borderRadius),
      ...factories.createBoxModel(padding),
      cellHeight: contentBoxCellHeight,
      cellHeightPercent,
      cellWidth: contentBoxCellWidth,
      cellWidthPercent,
      isNested,
    };

    const { IMAGE, LAYOUT_RENDITION, TEXT, CALL_TO_ACTION } = Constants.EntityType;
    const isKnownEntityType = [IMAGE, LAYOUT_RENDITION, TEXT, CALL_TO_ACTION].includes(entityType);
    if (isKnownEntityType && (!documentId || !this.documents.get(documentId))
    ) {
      return _.merge(baseCellSchema, factories.createText());
    }

    const document = this.documents.get(documentId);
    switch (entityType) {
      case Constants.EntityType.TEXT:
        return this.createCellSchemaForText(
          baseCellSchema,
          relation as Models.RegularRelationMap<Models.TextRelationStyles>,
        );

      case Constants.EntityType.REFERENCE_CITATION_ELEMENT: {
        const textSchema = this.convertReferenceCitation(
          relation as Models.RelationMap<Models.ReferenceCitationElementStyles>,
        );

        // Collapse empty reference citation elements
        if (!textSchema) {
          baseCellSchema.cellHeight = '0px';

          return _.merge(baseCellSchema, { entityType: CellEntityType.SPACER, height: '0px' });
        }

        return _.merge(baseCellSchema, textSchema);
      }

      case Constants.EntityType.ABBREVIATIONS_LIST:
        return this.createCellSchemaForAbbreviationsList(
          baseCellSchema,
          relation as AbbreviationsListRelationMap,
        );

      case Constants.EntityType.LAYOUT_RENDITION:
      case Constants.EntityType.IMAGE: {
        const imageSchema = this.convertImage(
          relation as Models.RegularRelationMap<Models.ImageRelationStyles>,
          document as Models.ImageMap | Models.ReusableLayoutMap,
          borderBoxCellWidth,
        );

        return _.merge(baseCellSchema, imageSchema);
      }

      case Constants.EntityType.CALL_TO_ACTION: {
        const buttonSchema = this.convertCallToAction(
          relation as Models.RegularRelationMap<Models.CallToActionStyles>,
          document as Models.CallToActionMap,
          borderBoxCellWidth,
        );

        return _.merge(baseCellSchema, buttonSchema);
      }

      default: return baseCellSchema as Schemas.CombinedCell;
    }
  }

  private createCellSchemaForText(
    baseCellSchema: Schemas.Cell,
    relation: Models.RegularRelationMap<Models.TextRelationStyles>,
  ): Schemas.TextCell {
    const relationId = relation.get('id');
    const documentId = relation.get('documentId');
    const document = this.documents.get(documentId);

    const styles = relation.get('styles');
    const brandStyle = { brandStyleId: styles.get('brandStyleId') };
    const brandStyleChanged = styles.get('brandStyleChanged');
    let editorState = editorUtils.getEditorStateFromTextComponent(
      document as Models.TextComponentMap,
      relation,
      this.citationsOrderByDocuments,
    );

    const isCellAutoHeight = editorUtils.hasToken(editorState) && !areThereHorizontalNeighbors(relationId, this.relations);
    const fonts = this.fontsByRelationId.get(relation.get('id'));
    const brandStyleFromRelation = this.brandStylesByRelationId.getIn([relationId, brandStyle.brandStyleId]);

    if (!brandStyleChanged && brandStyle.brandStyleId) {
      const brandStyleToApply = !brandStyleFromRelation ? this.brandStyles.get(DefaultTextBrandStyle) : brandStyleFromRelation;
      const textStyles = getTextStylesFromBrandStyle(brandStyleToApply, this.colors, fonts);

      editorState = editorUtils.toggleBrandStyle(editorState, textStyles, this.colors, fonts);
    }

    if (Constants.ProjectsConfig[this.projectType].applyFontStyleForBrandStyles) {
      editorState = editorUtils.applyFontStylesForBrandStyles(editorState, fonts);
    }
    editorState = editorUtils.applyFontStyles(editorState, fonts, this.projectType);

    const textSchema = this.convertEditorState(editorState, relationId, { brandStyleId: brandStyle.brandStyleId });

    const isAutoFitContent = relation.getIn(['styles', 'isAutoFitContent']);

    const custom: Partial<Schemas.TextCell> = { isCellAutoHeight, isAutoFitContent };
    if (brandStyleFromRelation) {
      const breakpointStyles = getDesktopBrandStyles(brandStyleFromRelation);
      const textTransform = breakpointStyles?.get(Models.BrandStyleProp.TEXT_TRANSFORM);
      if (textTransform && textTransform !== 'none') {
        custom.textTransform = textTransform;
      }
    }

    return _.merge(baseCellSchema, custom, brandStyle, textSchema);
  }

  protected abbreviationsMapping?: AbbreviationsMapping;

  protected createCellSchemaForAbbreviationsList(
    baseCellSchema: Schemas.Cell,
    relation: AbbreviationsListRelationMap,
  ): AbbreviationsListSchemaCell {
    if (!this.abbreviationsMapping) {
      this.abbreviationsMapping = abbreviationsListsFromContext({
        activeLayer: this.artboardsContext.get('activeLayer'),
        relations: this.artboardsContext.get('relationsOnScreens'),
        documents: this.artboardsContext.get('documentsByEntityType'),
        abbreviations: this.artboardsContext.get('abbreviationsData'),
      });
    }

    return createAbbreviationsListCellSchema(
      baseCellSchema,
      this.abbreviationsMapping,
      relation,
      this.fontsByRelationId.get(relation.get('id')),
      this.convertEditorState.bind(this),
      this.projectType,
    );
  }

  private convertEditorState(
    editorState: Draft.EditorState,
    relationId: string,
    options?: {
      brandStyleId?: string;
      defaultLineHeight?: Constants.TextLineHeightValue;
    },
  ): Schemas.ReferenceText {
    const {
      brandStyleId,
      defaultLineHeight,
    } = _.defaults(options, { defaultLineHeight: Constants.TextLineHeightValue.ONE_POINT_FIVE });
    const currentContent = editorState.getCurrentContent();
    const paragraphs: (Schemas.Paragraph | Schemas.ListParagraph)[] = [];
    let listItemParagraphs: Schemas.ListItemParagraph[] = [];
    const brandStyle = this.brandStylesByRelationId.getIn([relationId, brandStyleId]) as Models.BrandStyleMap | undefined;
    const colors = this.colorsByRelationId.get(relationId);
    const fonts = this.fontsByRelationId.get(relationId);
    const brandInlineStyles = brandStyle && getInlineStylesForExport(brandStyle, colors, fonts);

    let prevListBlockType = false;
    let rootParagraphColor = null;
    currentContent.getBlockMap().forEach((block: Draft.ContentBlock) => {
      let prevCutIndex = 0;
      let prevStyles: Schemas.ExtendedTextStyles;
      const segments: Schemas.Segment[] = [];
      const lineHeight = String(block.getIn(['data', Constants.BlockDataKey.LINE_HEIGHT]) || defaultLineHeight);
      const blockCharList = block.getCharacterList();
      const firstCharEntityKey = blockCharList.size !== 0 ? blockCharList.first().getEntity() : null;
      const firstCharEntity = firstCharEntityKey ? currentContent.getEntity(firstCharEntityKey) : undefined;
      let prevSegmentType = getSegmentType(firstCharEntity);
      segments.push(factories.createSegment(
        { type: prevSegmentType },
        firstCharEntity,
        this.surfaces,
      ));

      blockCharList.forEach((
        char: Draft.CharacterMetadata,
        i: number,
        characters: Immutable.List<Draft.CharacterMetadata>,
      ) => {
        const charStyles = char.getStyle();
        const charEntityKey = char.getEntity();
        const charEntity = charEntityKey ? currentContent.getEntity(charEntityKey) : undefined;
        const currentSegmentType = getSegmentType(charEntity);
        const newSegmentType = prevSegmentType !== currentSegmentType;
        const isLastChar = i === characters.count() - 1;

        const currentStyles = factories.createPartTextSytles(
          charStyles,
          { colors, fonts, brandStyle },
          lineHeight,
          charEntity,
        );
        const newStyles = prevStyles && !_.isEqual(prevStyles, currentStyles);
        const lastSegmentParts = segments[segments.length - 1].parts!;

        if (newStyles || newSegmentType || isLastChar) {
          if (isLastChar && newSegmentType) {
            const text = block.getText().slice(prevCutIndex, i);
            lastSegmentParts.push(factories.createPart({ text, ...prevStyles }));
            segments.push(factories.createSegment(
              {
                type: currentSegmentType,
                parts: [factories.createPart({ text: block.getText().split('').pop(), ...currentStyles })],
              },
              charEntity,
              this.surfaces,
            ));
          } else if (isLastChar && newStyles) {
            const text = block.getText().slice(prevCutIndex, i);
            lastSegmentParts.push(factories.createPart({ text, ...prevStyles }));
            lastSegmentParts.push(factories.createPart({ text: block.getText().split('').pop(), ...currentStyles }));
          } else if (isLastChar && !prevStyles) {
            lastSegmentParts.push(factories.createPart({ text: block.getText().split('').pop(), ...currentStyles }));
          } else {
            const endIndex = isLastChar ? i + 1 : i;
            const text = block.getText().slice(prevCutIndex, endIndex);
            lastSegmentParts.push(factories.createPart({ text, ...prevStyles }));
            if (newSegmentType) {
              segments.push(factories.createSegment(
                { type: currentSegmentType },
                charEntity,
                this.surfaces,
              ));
            }
          }
          prevCutIndex = i;
        }
        prevStyles = currentStyles;
        prevSegmentType = currentSegmentType;
      });

      const anchor: boolean = block.getIn(['data', Constants.BlockDataKey.ANCHOR]);
      const align = getParagraphAlign(block);
      const { allowCustomRangeFontSizeSelection } = Constants.ProjectsConfig[this.projectType];
      const fontSizeStyle = !allowCustomRangeFontSizeSelection
        ? blockCharList.size > 0
          ? editorUtils.getNonScriptedFontSize(blockCharList as unknown as Immutable.List<Draft.CharacterMetadata>)
          : { fontSize: (brandInlineStyles && brandInlineStyles.fontSize) || toPx(Constants.DefaultCustomStyle.FONT_SIZE) }
        : null;
      const fontSize = getIntegerFromStyle(fontSizeStyle && fontSizeStyle.fontSize || '');

      // DO NOT set font-size because it recalculates heights for texts with line-height
      if (allowCustomRangeFontSizeSelection && brandInlineStyles) {
        delete brandInlineStyles.fontSize;
      }

      const resultParagraph = factories.createTextParagraph({
        segments,
        ...brandInlineStyles,
        ...fontSizeStyle,
        align,
        lineHeight: fontSize ? toPx(fontSize * Number(lineHeight), 2) : lineHeight,
        anchor,
      });

      // recalculate fontSize and lineHeightPx for sub/sup scripts
      resultParagraph.segments.forEach((segment) => {
        segment.parts.forEach((part) => {
          const { sub, sup, fontSize, lineHeight } = part;
          if (sub || sup) {
            const partFontSize = fontSize === Constants.DEFAULT_SCRIPT_FONT_SIZE
              ? toPx(getIntegerFromStyle(resultParagraph.fontSize) * Constants.SCRIPT_BASIC_FONT_REDUCTION, 2)
              : fontSize;

            part.fontSize = partFontSize;
            part.lineHeightPx = toPx(parseFloat(partFontSize) * Number(lineHeight), 2);
          }
        });
      });

      if (editorUtils.isListBlock(block)) {
        const depth = block.getDepth();
        const { listStyleType, marginLeft } = Constants.Styles.ListItemByDepth[depth];
        const paragraphColor = _.get(resultParagraph, 'segments[0].parts[0].bulletColor', resultParagraph.color);

        if (depth === 0) {
          rootParagraphColor = paragraphColor;
        }

        const resultListItemParagraph = factories.createListItemParagraph({
          ...resultParagraph,
          color: rootParagraphColor ?? paragraphColor,
          level: depth,
          bulletWidth: marginLeft,
          listStyleType,
        });

        listItemParagraphs.push(resultListItemParagraph);
      } else {
        if (prevListBlockType) {
          const listParagraph = this.createListParagraph(listItemParagraphs);

          paragraphs.push(listParagraph);

          listItemParagraphs = [];
        }

        paragraphs.push(resultParagraph);
      }

      prevListBlockType = editorUtils.isListBlock(block);
    });

    if (_.size(listItemParagraphs) > 0) {
      const listParagraph = this.createListParagraph(listItemParagraphs);

      paragraphs.push(listParagraph);
    }

    return factories.createText({ paragraphs });
  }

  private createListParagraph(listItemParagraphs: Schemas.ListItemParagraph[], isReference = false): Schemas.ListParagraph {
    const indent = isReference ? getReferenceIndent(this.projectType) : getListIndent(this.projectType, listItemParagraphs);

    return factories.createListParagraph({
      ...indent,
      paragraphs: listItemParagraphs,
    });
  }

  private convertReferenceCitation(
    relation: Models.RelationMap<Models.ReferenceCitationElementStyles>,
  ): Schemas.ReferenceText | undefined {
    const relationId = relation.get('id');
    const fontColor = relation.getIn(['styles', 'fontColor']);
    const fontSize = relation.getIn(['styles', 'fontSize']);
    const lineHeight = relation.getIn(['styles', 'lineHeight']);
    const alignment = relation.getIn(['styles', 'alignment']);
    const fontFamily = this.createFontFamily(relationId);
    const fonts = this.fontsByRelationId.get(relationId);

    const referencesOrder = this.referenceCitationsByReferenceElements.get(relationId);

    const isReferencesOrderEmpty = referencesOrder.size === 0;
    if (isReferencesOrderEmpty) {
      return;
    }

    let editorState = editorUtils.htmlToEditorState(getReferencesAsString(referencesOrder, this.projectType));
    editorState = editorUtils.applyFontStyles(editorState, fonts, this.projectType);
    const textSchema = this.convertEditorState(editorState, relationId);

    textSchema.paragraphs.forEach((paragraph: Schemas.TextParagraph) => {
      paragraph.fontSize = toPx(fontSize);
      paragraph.color = fontColor;
      paragraph.fontFamily = fontFamily;
      paragraph.lineHeight = String(lineHeight || Constants.TextLineHeightValue.ONE_POINT_FIVE);
      _.merge(paragraph, getAlignment(alignment));

      paragraph.segments.forEach((segment) => {
        segment.parts.forEach((part) => {
          const partFontSize = part.sub || part.sup
            ? editorUtils.getScriptFontsize(Number(fontSize))
            : toPx(fontSize);

          part.fontFamily = fontFamily;
          part.fontSize = partFontSize;
          part.lineHeight = lineHeight;
          part.lineHeightPx = partFontSize ? toPx(getIntegerFromStyle(partFontSize) * Number(lineHeight), 2) : lineHeight;
          part.color = fontColor;

          if (this.projectType === ProjectType.EMAIL && segment.type === SegmentType.TEXT) {
            part.text = addInvisibleJoinersToUrls(part.text);
          }
        });
      });
    });
    textSchema.isReferenceCitation = true;

    const { wrapReferenceInOrderedList } = Constants.ProjectsConfig[this.projectType];
    if (wrapReferenceInOrderedList) {
      textSchema.paragraphs = [this.createListParagraph(textSchema.paragraphs as Schemas.ListItemParagraph[], true)];
    }

    return textSchema;
  }

  private convertSpacer(relation: Models.RegularRelationMap<Models.SpacerRelationStyles>): Schemas.Spacer {
    const styles = relation.get('styles');
    const height = toPx(styles.get('height'));

    return factories.createSpacer({ height });
  }

  private createFontFamily(relationId: string): string {
    const relation = this.relations.get(relationId) as Models.RegularRelationMap<Models.CallToActionStyles | Models.ReferenceCitationElementStyles>;
    const relationStyles = relation.get('styles');

    const fonts = this.fontsByRelationId.get(relationId);
    const fontFamily = relationStyles.get('fontFamily');
    const fontStyle = relationStyles.get('fontStyle');

    return BrandDefinition.getCSSFontFamilyFromBrandFont(fontFamily, fontStyle, fonts);
  }

  private convertCallToAction(
    relation: Models.RegularRelationMap<Models.CallToActionStyles>,
    document: Models.CallToActionMap,
    columnWidth: number,
  ): Schemas.Button {
    const { useContentBox } = Constants.ProjectsConfig[this.projectType];
    const relationId = relation.get('id');
    const relationStyles = relation.get('styles');
    const fonts = this.fontsByRelationId.get(relationId);
    const colors = this.colorsByRelationId.get(relationId);
    const { link, name } = document.toJS() as Models.CallToAction;
    const buttonBackgroundColor = getBackgroundColor(
      this.projectType,
      colors,
      relationStyles.get('assetBackgroundColor'),
      relationStyles.get('assetBackgroundColorTint'),
      relationStyles.get('assetBackgroundOpacity'),
      Constants.DefaultCallToActionBackgroundColor,
    );
    const buttonBackgroundGradient = getBackgroundGradient(
      this.projectType,
      colors,
      relationStyles.get('assetBackgroundGradient'),
    );

    const assetBorderRadius = relationStyles.get('assetBorderRadius');
    const relationPadding = relationStyles.get('assetPadding').toJS() as Models.Padding;
    const width = useContentBox ? relationStyles.get('width') - relationPadding.left - relationPadding.right : relationStyles.get('width');
    const height = useContentBox ? relationStyles.get('height') - relationPadding.top - relationPadding.bottom : relationStyles.get('height');
    const textAlignment = getAlignment(relationStyles.get('textAlignment'));
    const buttonPadding = boxPropertyToString(relationPadding);

    const fitToCell = relationStyles.get('fitToCell');
    const cellHeight = getCellHeight(this.getCurrentArtboardId(), relation, this.cellsHeight);

    const editorState = editorUtils.getEditorStateFromCallToAction(document, relation, colors, fonts, this.projectType);
    const textSchema = this.convertEditorState(editorState, relationId, { defaultLineHeight: Constants.TextLineHeightValue.ONE_POINT_FIFTEEN });

    // for CTA with multiple fonts used we are using first font to apply to CTA itself
    const CTAparagraphsFontFamilyCleared = textSchema.paragraphs.map((paragraph: Schemas.TextParagraph) => {
      const firstPartFontFamily = paragraph.segments?.[0]?.parts?.[0]?.fontFamily;

      return {
        ...paragraph,
        fontFamily: firstPartFontFamily ?? paragraph?.fontFamily,
      };
    });

    const { href, screenRef } = getLinks(link, this.surfaces);

    return {
      textAlignment: factories.createAlignment(textAlignment),
      buttonBorderRadius: boxPropertyToString(assetBorderRadius && assetBorderRadius.toJS() as Models.BorderRadius),
      buttonBackgroundColor,
      buttonBackgroundGradient,
      buttonPadding,
      width: fitToCell ? toPx(columnWidth) : toPx(Math.min(width, columnWidth)),
      height: fitToCell ? cellHeight : toPx(height),
      text: name.replace(NEW_LINE_REG_EXP, _.escape(NEW_LINE_DELIMITER)),
      href: removeScriptTag(href),
      screenRef,
      paragraphs: CTAparagraphsFontFamilyCleared,
      entityType: CellEntityType.BUTTON,
    };
  }

  private convertImage(
    relation: Models.RegularRelationMap<Models.ImageRelationStyles>,
    document: Models.ImageMap | Models.ReusableLayoutMap,
    columnWidth: number,
  ): Schemas.Image {
    let imageSource: string;
    let docImageWidth: number;
    let docImageHeight: number;

    if (isImage(document)) {
      imageSource = document.getIn(['_internalInfo', 'source']);
      docImageWidth = document.getIn(['_internalInfo', 'width']);
      docImageHeight = document.getIn(['_internalInfo', 'height']);
    } else {
      imageSource = document.get('_thumbnailUrl');
      docImageWidth = document.get('_thumbnailWidth');
      docImageHeight = document.get('_thumbnailHeight');
    }

    const documentId = document.get('documentId');
    const link = relation.getIn(['styles', 'link']);
    const title = relation.getIn(['styles', 'altTag']);
    const mobile = allowImageMobileSettings(this.projectType, this.rootDocument)
      ? getImageMobileSettings(relation, this.documents)
      : {} as Schemas.ImageMobileSettings;

    return factories.createImage({
      width: getImageWidth(relation, docImageWidth, docImageHeight, columnWidth, this.getCellHeight(relation)),
      alt: title || Constants.DEFAULT_IMAGE_ALT_TAG,
      title,
      src: imageSource,
      documentId,
      ...getLinks(link, this.surfaces),
      ...{ mobile },
    });
  }

  private convertBackgroundImage(
    backgroundImage?: Models.BackgroundImageMap,
    allowBackgroundImage = true,
  ): Schemas.BackgroundImage {
    if (!allowBackgroundImage || !backgroundImage) {
      return factories.createBackgroundImage();
    }
    const backgroundImageId = backgroundImage.get('id');
    const backgroundImageDocumentId = this.documents.getIn([backgroundImageId, 'documentId']);

    return factories.createBackgroundImage({
      ...getBackgroundImage(backgroundImage, this.documents),
      backgroundImageDocumentId: backgroundImageDocumentId && String(backgroundImageDocumentId),
    } as Schemas.BackgroundImage);
  }

  private setCurrentSectionId(sectionId: string): void {
    this.currentSectionId = sectionId;
  }

  private getCurrentSectionId(): string {
    return this.currentSectionId;
  }

  private getCurrentSectionWidth(): number {
    const screenId = this.getCurrentScreenId();
    const sectionId = this.getCurrentSectionId();
    const sectionName = this.sections.getIn([sectionId, 'name']);

    return this.sectionsWidthByScreen.getIn([screenId, sectionName]);
  }

  private activateSSIMode(): void {
    this.isSSIMode = true;
  }

  private setCurrentArtboardId(artboardId: string): void {
    this.currentArtboardId = artboardId;
  }

  private getCurrentArtboardId(): string {
    return this.currentArtboardId;
  }

  private getCurrentScreenId(): string {
    return this.surfaces.find(surface => surface.get('artboardId') === this.getCurrentArtboardId()).get('id');
  }

  private setOptions(options?: CreateArtboardsJsonOptions): void {
    this.options = _.defaults(options, {
      screenIds: this.surfaces.keySeq().toArray(),
      screenOptionsById: {},
    } as CreateArtboardsJsonOptions);
  }

  private getCurrentScreenOptions(): ScreenOptions {
    const currentScreenId = this.getCurrentScreenId();

    return _.defaults(this.options.screenOptionsById[currentScreenId], {
      allowArtboardBackground: true,
      allowArtboardHeight: true,
      allowSectionBackground: true,
      allowSectionHeight: true,
      allowSSI: true,
      isLayoutPreview: false,
    } as ScreenOptions);
  }
}
