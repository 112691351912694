import { useCallback, useMemo, useState } from 'react';
import { IMap } from 'typings/DeepIMap';
import * as Constants from 'const';
import * as Models from 'models';
import { getChangedStylesToBeKept } from 'utils/styles/getStylesToBeKept';
import { getPrioritizedRelation } from '../utils/relation';
import { Styles, StylesSetters, getInitialStyles, stylesFromSource } from '../utils/styles';
import { BrandProps } from './useBrandProps';

type StylesHook = {
  styles: Styles;
  stylesSetters: StylesSetters;
  resetStyles: (
    source: IMap<Models.TextRelationStyles>,
    brandStyles: Models.BrandStylesMap,
    brandStyleId: string,
    brandStyleChanged: boolean,
    colors: Models.BrandColorsList,
  ) => void;
  ensureStylesToBeKept: (
    relation: Models.LayeredRegularRelationMap<Models.TextRelationStyles>,
    activeLayer: Constants.Layer,
    brandProps: BrandProps,
  ) => void;
  applyBrandStyle:(
    brandStyle: Models.BrandStyleMap,
    brandStyleValues: Models.TextBrandStyles,
    brandStyleChanged: boolean,
  ) => void;
};

export default function useStyles(
  source: IMap<Models.TextRelationStyles>,
  brandProps: BrandProps,
): StylesHook {
  const [styles, setStyles] = useState<Styles>(() => getInitialStyles(source, brandProps));

  const resetStyles = useCallback((
    src: IMap<Models.TextRelationStyles>,
    brandStyles: Models.BrandStylesMap,
    brandStyleId: string,
    brandStyleChanged: boolean,
    colors: Models.BrandColorsList,
  ) => setStyles({
    ...stylesFromSource(src, brandStyles, colors),
    brandStyle: brandStyles.get(brandStyleId),
    brandStyleChanged,
  })
  , []);

  const setters = useMemo((): StylesSetters => {
    return {
      backgroundColor: color => setStyles(prev => ({
        ...prev,
        backgroundColor: color,
        backgroundGradient: undefined,
        brandStyleChanged: true,
      })),
      backgroundColorOpacity: backgroundColorOpacity => setStyles(prev => ({
        ...prev,
        backgroundColorOpacity,
        brandStyleChanged: true,
      })),
      backgroundGradient: (gradient, backupColor) => setStyles(prev => ({
        ...prev,
        backgroundColor: backupColor,
        backgroundGradient: gradient,
        brandStyleChanged: true,
      })),
      backgroundImage: backgroundImage => setStyles(prev => ({ ...prev, backgroundImage })),
      border: border => setStyles(prev => ({ ...prev, border })),
      borderRadius: borderRadius => setStyles(prev => ({ ...prev, borderRadius })),
      brandStyle: brandStyle => setStyles(prev => ({ ...prev, brandStyle })),
      brandStyleChanged: (value = true) => setStyles(prev => ({ ...prev, brandStyleChanged: value })),
      padding: padding=> setStyles(prev => ({
        ...prev,
        padding,
        brandStyleChanged: true,
      })),
      verticalAlignment: verticalAlignment => setStyles(prev => ({
        ...prev,
        verticalAlignment,
        brandStyleChanged: true,
      })),
    };
  }, []);

  // TODO: it looks like overhead
  const ensureStylesToBeKept = useCallback((
    relation: Models.LayeredRegularRelationMap<Models.TextRelationStyles>,
    activeLayer: Constants.Layer,
    { fonts, colors, brandStyles }: BrandProps,
  ): void => {
    const newStyles = stylesFromSource(
      relation.getIn(['styles', activeLayer]),
      brandStyles,
      colors,
    );

    const {
      backgroundColor,
      backgroundColorTint,
      backgroundColorOpacity,
      backgroundGradient,
      backgroundImage,
      border,
      padding,
    } = getChangedStylesToBeKept(
      getPrioritizedRelation({ relation, activeLayer }),
      brandStyles,
      colors,
      fonts,
    );

    if (backgroundGradient) {
      setters.backgroundGradient(newStyles?.backgroundGradient, newStyles?.backgroundColor);
    } else if (backgroundColor || backgroundColorTint) {
      setters.backgroundColor(newStyles?.backgroundColor);
    }

    if (backgroundColorOpacity) {
      setters.backgroundColorOpacity(backgroundColorOpacity);
    }

    if (backgroundImage) {
      setters.backgroundImage(newStyles?.backgroundImage);
    }

    if (border) {
      setters.border(newStyles?.border);
    }

    if (padding) {
      setters.padding(newStyles?.padding);
    }
  }, [setters]);

  const applyBrandStyle = useCallback((
    brandStyle: Models.BrandStyleMap,
    brandStyleValues: Models.TextBrandStyles,
    brandStyleChanged: boolean,
  ) => setStyles(prev => ({
    ...prev,
    padding: brandStyleValues.padding,
    verticalAlignment: brandStyleValues.verticalAlignment,
    backgroundColor: brandStyleValues.backgroundColor,
    backgroundGradient: undefined, // IN-PROGRESS: should be refactored
    brandStyle,
    brandStyleChanged,
  })), []);

  return {
    styles,
    stylesSetters: setters,
    resetStyles,
    ensureStylesToBeKept,
    applyBrandStyle,
  };
}
