import classNames from 'classnames';
import { CellAction, useCellActionsExecutor } from 'context/CellActions';
import { TextEditor, wrapperOnMouseDownHackStrict } from 'modules/draftjs';
import React, { MutableRefObject, useCallback, useEffect, useRef, lazy, Suspense } from 'react';
import EditorToolbar from 'components/EditorToolbar';
import { BoxPropertySide } from 'const';
import * as editorUtils from 'utils/editor';
import { intlGet } from 'utils/intlGet';
import { areThereHorizontalNeighbors } from 'utils/rowsHeight/areThereHorizontalNeighbors';
import UndoPortal from './components/UndoPortal';
import useBrandProps from './hooks/useBrandProps';
import useBrandStyleChange from './hooks/useBrandStyleChange';
import useCell from './hooks/useCell';
import useEditor from './hooks/useEditor';
import { EditorHook } from './hooks/useEditorInterface';
import { EditorMode, useEditorToggle } from './hooks/useEditorToggle';
import { useLexicalTextEditor } from './hooks/useLexicalTextEditor';
import useResizeObserver from './hooks/useResizeObserver';
import useSave from './hooks/useSave';
import useStyles from './hooks/useStyles';
import useUndo, { cancelUndoStack } from './hooks/useUndo';
import useUpdate from './hooks/useUpdate';
import { TextProps } from './models';
import css from './styles.module.scss';
import { getInitialEditorOtherState, getInitialEditorState } from './utils/editor';
import { stylesToCSS } from './utils/styles';
import { wrapEditorSettersWithUndoMiddleware, wrapStylesSettersWithUndoMiddleware } from './utils/undo';

const LexicalTextEditor = lazy(
  () => import('./components/LexicalTextEditor')
    .then(module => ({ default: module.LexicalTextEditor })),
);

export function getTextDOMId(relationId: string): string {
  return `text-cell-${relationId}`;
}

const Text: React.FunctionComponent<TextProps> = (props) => {
  const {
    layoutId,
    projectType,
    relation,
    sectionStyles,
    images,
    placeholderMinHeight,
    shrinkable,
    editMode,
    notEditable,
    isDraggingAsset,
    layoutRelations,
    activeLayer,
    ssi,
    document,
  } = props;

  const editorMode = useEditorToggle();
  const editorModeIsLexical = editorMode === EditorMode.LEXICAL;

  const wrapperRef = useRef<HTMLDivElement>() as MutableRefObject<HTMLDivElement>;
  const brandProps = useBrandProps(props);

  const source = relation.getIn(['styles', activeLayer]) || new Map();
  const stylesHook = useStyles(source, brandProps);
  const onBeforeEditorStateChangeRef = useRef<(() => void)>();

  const draftjsHook = useEditor(
    props,
    brandProps,
    stylesHook.styles.brandStyle,
    stylesHook.stylesSetters.brandStyleChanged,
    () => getInitialEditorState(props, brandProps),
    () => getInitialEditorOtherState(props, brandProps),
    onBeforeEditorStateChangeRef.current,
  );
  const lexicalHook = useLexicalTextEditor();

  const editorTextContent = editorModeIsLexical
    ? lexicalHook.textContent.trim()
    : draftjsHook.editorState.getCurrentContent().getPlainText().trim();

  const editorHook: EditorHook = {
    hasTextContent: Boolean(editorTextContent.length),
    hasCustomToken: editorUtils.hasToken(editorTextContent),
    applyBrandStyleValues: editorModeIsLexical ? lexicalHook.applyBrandStyleValues : draftjsHook.applyBrandStyleValues,
    chooseAbbreviation: editorModeIsLexical ? lexicalHook.chooseAbbreviation : draftjsHook.chooseAbbreviation,
  };

  const cellHook = useCell(
    props,
    wrapperRef,
    editorHook,
    stylesHook.styles,
  );
  const { brandStyleChanged: setBrandStyleChanged } = stylesHook.stylesSetters;
  const { toggleAutoFitContent, props: { isAutoFitContent } } = cellHook;
  const toggleAutoFitContentWithBrandStyleChange = useCallback(() => {
    toggleAutoFitContent();
    if (isAutoFitContent) {
      setBrandStyleChanged();
    }
  }, [setBrandStyleChanged, toggleAutoFitContent, isAutoFitContent]);

  const storeText = useSave(
    props,
    brandProps,
    editorHook,
    draftjsHook,
    cellHook,
    stylesHook,
  );

  const undoHook = useUndo(draftjsHook, stylesHook, cellHook);
  onBeforeEditorStateChangeRef.current = undoHook.updateUndoRedoBeforeChange;

  const brandStyleChangeHook = useBrandStyleChange(
    brandProps,
    editorHook.applyBrandStyleValues,
    cellHook,
    stylesHook,
  );

  useResizeObserver(wrapperRef.current, cellHook.resetHeight);
  useEffect(() => {
    const wrapper = wrapperRef.current;

    if (!wrapper) {return;}
    wrapper.addEventListener('beforeinput', cancelUndoStack);

    return (): void => wrapper.removeEventListener('beforeinput', cancelUndoStack);
  }, [wrapperRef.current]);

  useEffect(()=> {
    cellHook.resetHeight();
  }, [
    stylesHook.styles.padding?.get(BoxPropertySide.TOP),
    stylesHook.styles.padding?.get(BoxPropertySide.BOTTOM),
  ]);

  useUpdate(
    props,
    draftjsHook,
    brandProps,
    stylesHook,
    cellHook,
    undoHook,
    storeText,
  );

  const { undoStackMiddleware } = undoHook;
  const stylesSettersWithUndo = wrapStylesSettersWithUndoMiddleware(stylesHook.stylesSetters, undoStackMiddleware);
  const editorSettersWithUndo = wrapEditorSettersWithUndoMiddleware(draftjsHook.setters, undoStackMiddleware);

  const isMultiColumn = areThereHorizontalNeighbors(relation.get('id'), layoutRelations);
  const isAutoHeight = editorHook.hasCustomToken && !isMultiColumn;
  const notShowPlaceholder = notEditable || editMode || editorHook.hasTextContent;

  useCellActionsExecutor(CellAction.SET_CURSOR_ON_ABBREVIATION, editorHook.chooseAbbreviation);

  useCellActionsExecutor(CellAction.APPLY_SELECTION, (
    blockKey: string,
    start: number,
    end: number,
  ) => {
    draftjsHook.setEditorState(editorUtils.applySelection(draftjsHook.editorState, blockKey, start, end));
  });

  const { colors, fonts } = brandProps;
  if (!colors || !fonts) {
    return null;
  }

  return (
    <div
      role='presentation'
      className={classNames(css.Text, {
        [css.severalColumns]: cellHook.props.cellsCount > 1,
        [css.noMinHeight]: editorHook.hasTextContent || editMode || shrinkable || cellHook.props.cellHeight,
        [css.fullHeight]: cellHook.props.isLastCell || cellHook.props.cellHeight,
      })}
      style={stylesToCSS(stylesHook.styles, { images, placeholderMinHeight })}
      onMouseDown={wrapperOnMouseDownHackStrict}
    >
      {editMode && (
        <>
          <UndoPortal
            undo={undoHook.undo}
            redo={undoHook.redo}
            isRedoDisabled={undoHook.isRedoDisabled}
            isUndoDisabled={undoHook.isUndoDisabled}
          />
          <EditorToolbar
            layoutId={layoutId}
            projectType={projectType}
            returnFocusToEditor={draftjsHook.returnFocusToEditor}
            editorProps={editorModeIsLexical ? lexicalHook.props : draftjsHook.props}
            editorSetters={editorModeIsLexical ? lexicalHook.setters : editorSettersWithUndo}
            styles={stylesHook.styles}
            stylesSetters={stylesSettersWithUndo}
            cellProps={cellHook.props}
            toggleAutoFitContent={toggleAutoFitContentWithBrandStyleChange}
            toggleCellHeight={undoStackMiddleware(cellHook.toggleCellHeight, 'cellHeight')}
            toggleColumnWidth={undoStackMiddleware(cellHook.toggleCellWidth, 'cellWidth')}
            isAutoHeight={isAutoHeight}
            beforeAutoFitMouseDown={undoHook.updateUndoRedoBeforeChange}
            setBrandStyle={undoStackMiddleware(brandStyleChangeHook.setBrandStyle, 'brandStyle')}
            sectionStyles={sectionStyles}
          />
        </>
      )}
      <div className={classNames({
        [css.editorModeBothWrapper]: editorMode === EditorMode.BOTH,
        [css.editorModeLexicalWrapper]: editorMode === EditorMode.LEXICAL,
      })}>
        {[EditorMode.BOTH, EditorMode.DRAFTJS].includes(editorMode) && (
          <TextEditor
            id={getTextDOMId(relation.get('id'))}
            ref={draftjsHook.editorRef}
            wrapperRef={wrapperRef}
            editorState={draftjsHook.editorState}
            onEditorChange={draftjsHook.onEditorChange}
            returnFocusToEditor={draftjsHook.returnFocusToEditor}
            setEditorState={draftjsHook.setEditorState}
            setEditorStateAndOperations={draftjsHook.setEditorStateAndOperations}
            addOperation={draftjsHook.addOperation}
            editMode={editMode}
            placeholder={notShowPlaceholder ? undefined : intlGet('Artboard.Layout.Text', 'Hint')}
            brandProps={brandProps}
            isDraggingAsset={isDraggingAsset}
            undoStackMiddleware={undoStackMiddleware}
            undo={undoHook.undo}
            redo={undoHook.redo}
            fillUndoStackIfEmpty={undoHook.fillUndoStackIfEmpty}
            projectType={projectType}
            dropOptions={props} // IN-PROGRESS it will be nide to pass only dropOptions
            storeTextCallback={storeText}
            ssiPosition={notEditable && ssi?.get('position')}
            document={document}
          />
        )}
        {[EditorMode.BOTH, EditorMode.LEXICAL].includes(editorMode) && (
          <div className={css.lexicalEditor}>
            <Suspense fallback={<div>Loading...</div>}>
              <LexicalTextEditor
                ref={lexicalHook.ref}
                draftContent={draftjsHook.editorState.getCurrentContent()}
                editMode={editMode}
                brandStyle={stylesHook.styles.brandStyle}
                brandProps={brandProps}
                onChange={lexicalHook.onChange}
              />
            </Suspense>
          </div>
        )}
      </div>
    </div>
  );
};


export default Text;
