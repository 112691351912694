import React, { useEffect } from 'react';
import Icon from 'components/Icon';
import { IconType } from 'components/Icon/constants';
import { ExtendedAssemblerError } from 'models';
import { intlGet } from 'utils/intlGet';

function getCopiedMessage(asmolError: ExtendedAssemblerError): string {
  const listOfDocumentIds = asmolError.documentIds?.reduce((finalString, documentId, index) => {
    return `${finalString} ${index + 1}. ${documentId}\n`;
  }, '') ?? '';

  return `Veeva PromoMats experienced a problem
${asmolError.message}
Global ID of the documents with which the issue occurred:
${listOfDocumentIds}
Technical details which are required for investigation (please don't delete):
Request ID: ${asmolError.awsRequestId ?? 'unknown'};
Type: ${asmolError.type};
TS: ${Date.now()};
UserId: ${asmolError.userId ?? 'unknown'}.`;
}

const CopyErrorIcon: React.FunctionComponent<{ asmolError: ExtendedAssemblerError }> = ({ asmolError }) => {
  const [descriptionCopied, setDescriptionCopied] = React.useState(false);
  const [timeoutId, setTimeoutId] = React.useState<NodeJS.Timeout>();

  const onClickCopy = (): void => {
    setDescriptionCopied(true);
    void navigator.clipboard.writeText(
      getCopiedMessage(asmolError),
    );
    setTimeoutId(
      setTimeout(() => {
        setDescriptionCopied(false);
      }, 2000),
    );
  };

  useEffect(() => {
    return () => {
      clearTimeout(timeoutId);
    };
  });


  if (descriptionCopied) {
    return (<>
      <Icon type={IconType.CHECK_MARK} color='grey' size='md' className='px-2'/>
      <span>Copied!</span>
    </>);
  }

  return (<>
    <Icon type={IconType.CONTENT_COPY_TWO} color='m-teal' size='md' className='px-2 mt-2' onClick={onClickCopy}/>

    <span className='pl-2'>{intlGet('Notification.PromoMatsError.AccessError', 'CopyClick')}</span>
  </>);
};

export default CopyErrorIcon;
