import Draft from 'draft-js';
import _ from 'lodash';
import * as BrandDefinition from 'modules/BrandDefinition';
import { select } from 'redux-saga/effects';
import { IList } from 'typings/DeepIMap';
import { InlineFontStyleNoPrefix, OldInlineStyle, ProjectsConfig } from 'const';
import {
  UIFontFaces as UIFontFacesSelector,
  flatColorsByRelationId as flatColorsByRelationIdSelector,
  flatFontsByRelationId as flatFontsByRelationIdSelector,
} from 'containers/Common/selectors';
import * as documentsSelectors from 'containers/Documents/selectors';
import * as ProjectSelectors from 'containers/Project/selectors';
import { relations as relationsSelector } from 'containers/Relations/selectors';
import * as Models from 'models';
import { applyFontStyles, applyFontStylesForBrandStyles } from 'utils/editor/style';
import { getEditorStateFromCallToAction, getEditorStateFromTextComponent, htmlToEditorState } from 'utils/editor/text';
import { isCallToAction, isReferenceCitationElementRelation, isTextComponent } from 'utils/entityType';
import { getReferencesAsString } from 'utils/getReferencesAsString';
import * as inlineStylesUtils from 'utils/inlineStyles';

type InlineStylesMap = Immutable.Iterable<number, Draft.DraftInlineStyle>;
type StylesHashMap = Immutable.Iterable<number, string>;
type FontsList = IList<Models.BrandFont>;

const getHash = (fontFamily: string, fontWeight?: string | null, fontStyle?: string): string => {
  return `${fontFamily}:${fontWeight || ''}:${fontStyle || ''}`;
};

const parseHash = (hash: string): string[] => {
  return hash.split(':');
};

const getFontStyles = (styles: Draft.DraftInlineStyle): { fontStyle: string; fontWeight: string } => {
  const fontWeightStyle = inlineStylesUtils.findFontWeightStyle(styles);
  const fontStyleStyle = inlineStylesUtils.findFontStyleStyle(styles);

  const fontStyle = fontStyleStyle
    ? inlineStylesUtils.getFontStyleFromStyle(fontStyleStyle)
    : styles.includes(OldInlineStyle.ITALIC)
      ? InlineFontStyleNoPrefix.ITALIC
      : InlineFontStyleNoPrefix.NORMAL;

  const fontWeight = fontWeightStyle
    ? inlineStylesUtils.getFontWeightFromStyle(fontWeightStyle)
    : styles.includes(OldInlineStyle.BOLD)
      ? InlineFontStyleNoPrefix.BOLD
      : InlineFontStyleNoPrefix.REGULAR;

  return {
    fontStyle,
    fontWeight,
  };
};

const getNormalizedMainFontFamily = (fontFamily: string): string => {
  return fontFamily.split(',')[0].trim().replace(/['"]/g, '');
};

const getCharactersStyles = (editorState: Draft.EditorState): InlineStylesMap => {
  return editorState
    .getCurrentContent()
    .getBlockMap()
    .toList()
    .flatMap(block => block?.getCharacterList().map(char => char?.getStyle()));
};

const getFontsHashes = (fonts: FontsList, charactersStyles: InlineStylesMap): StylesHashMap => {
  return charactersStyles
    .map((styles) => {
      const fontFamilyStyle = inlineStylesUtils.findFontFamilyStyle(styles as Draft.DraftInlineStyle);
      const characterStyleNameStyle = inlineStylesUtils.findCharacterStyleNameStyle(styles as Draft.DraftInlineStyle);
      const fontFamily = BrandDefinition.getCSSFontFamilyFromBrandFont(
        fontFamilyStyle && inlineStylesUtils.getFontFamilyFromStyle(fontFamilyStyle),
        characterStyleNameStyle && inlineStylesUtils.getCharacterStyleNameFromStyle(characterStyleNameStyle),
        fonts,
      );
      const mainFontFamily = getNormalizedMainFontFamily(fontFamily);
      const { fontStyle, fontWeight } = getFontStyles(styles as Draft.DraftInlineStyle);

      return getHash(mainFontFamily, fontWeight, fontStyle);
    });
};

const addFontsHashes = (editorState: Draft.EditorState, fonts: FontsList, usedFontFaceHashes: Set<string>): void => {
  const charactersStyles = getCharactersStyles(editorState);
  const fontsHashes = getFontsHashes(fonts, charactersStyles);
  fontsHashes.forEach((hash) => {
    if (!hash) {
      return;
    }
    usedFontFaceHashes.add(hash);
  });
};

export function* getUsedFontFaces(): Generator<unknown, Models.UIFontFace[]> {
  const projectType: ReturnTypeSaga<typeof ProjectSelectors.projectType> = yield select(ProjectSelectors.projectType);
  const relations: ReturnTypeSaga<typeof relationsSelector> = yield select(relationsSelector);
  const documents: ReturnTypeSaga<typeof documentsSelectors.documents> = yield select(documentsSelectors.documents);
  const UIFontFaces: ReturnTypeSaga<typeof UIFontFacesSelector> = yield select(UIFontFacesSelector);
  const colorsByRelationId: ReturnTypeSaga<typeof flatColorsByRelationIdSelector> = yield select(flatColorsByRelationIdSelector);
  const fontsByRelationId: ReturnTypeSaga<typeof flatFontsByRelationIdSelector> = yield select(flatFontsByRelationIdSelector);
  const referenceCitationsOrderByDocuments: ReturnTypeSaga<typeof documentsSelectors.referenceCitationsOrderByDocuments> = yield select(
    documentsSelectors.referenceCitationsOrderByDocuments,
  );
  const referenceCitationsByReferenceElements: ReturnTypeSaga<typeof documentsSelectors.referenceCitationsByReferenceElements> = yield select(
    documentsSelectors.referenceCitationsByReferenceElements,
  );
  const usedFontFaceHashes = new Set<string>();

  relations.forEach((relation, relationId) => {
    if (!relation || !relationId) {
      return;
    }

    const documentId = (relation as Models.RegularRelationMap).get('documentId');

    const fonts = fontsByRelationId.get(relationId);
    const colors = colorsByRelationId.get(relationId);

    if (isReferenceCitationElementRelation(relation)) {
      const styles = relation.get('styles');
      const fontFamily = BrandDefinition.getCSSFontFamilyFromBrandFont(
        styles.get('fontFamily'),
        styles.get('fontStyle'),
        fonts,
      );
      const mainFontFamily = getNormalizedMainFontFamily(fontFamily);

      const referencesOrder = referenceCitationsByReferenceElements.get(relationId);
      const editorState = htmlToEditorState(getReferencesAsString(referencesOrder, projectType));
      const charactersStyles = getCharactersStyles(editorState);

      charactersStyles.forEach((characterStyles) => {
        if (characterStyles) {
          const { fontStyle, fontWeight } = getFontStyles(characterStyles);
          const hash = getHash(mainFontFamily, fontWeight, fontStyle);
          usedFontFaceHashes.add(hash);
        }
      });
    }

    if (!documentId) {
      return;
    }

    if (isTextComponent(relation)) {
      const document = documents.get(documentId) as Models.TextComponentMap;
      let editorState = getEditorStateFromTextComponent(document, relation, referenceCitationsOrderByDocuments);
      if (ProjectsConfig[projectType].applyFontStyleForBrandStyles) {
        editorState = applyFontStylesForBrandStyles(editorState, fonts);
      }
      editorState = applyFontStyles(editorState, fonts, projectType);
      addFontsHashes(editorState, fonts, usedFontFaceHashes);
    }

    if (isCallToAction(relation)) {
      const document = documents.get(documentId) as Models.CallToActionMap;
      const editorState = getEditorStateFromCallToAction(document, relation, colors, fonts, projectType);
      addFontsHashes(editorState, fonts, usedFontFaceHashes);
    }
  });

  const resultFontFaces: Models.UIFontFace[] = [];
  const fontFaces = UIFontFaces.toJS() as Models.UIFontFace[];
  const fontFacesByHash = _.groupBy(fontFaces, ({ fontFamily, fontWeight, fontStyle }) => getHash(fontFamily, fontWeight, fontStyle));

  usedFontFaceHashes.forEach((hash) => {
    const normalizedHash = hash.replaceAll('\'', '');
    const [fontFamily, fontWeight, fontStyle] = parseHash(normalizedHash);
    let resultFontFace = fontFacesByHash[getHash(fontFamily)];

    if (fontWeight && fontStyle) {
      const fontFace = fontFacesByHash[getHash(fontFamily, fontWeight, fontStyle)];
      resultFontFace = fontFace || resultFontFace;
    } else if (fontStyle) {
      const fontFace = fontFacesByHash[getHash(fontFamily, null, fontStyle)];
      resultFontFace = fontFace || resultFontFace;
    } else if (fontWeight) {
      const fontFace = fontFacesByHash[getHash(fontFamily, fontWeight)];
      resultFontFace = fontFace || resultFontFace;
    }
    if (!resultFontFace) {
      return;
    }
    resultFontFaces.push(...resultFontFace);
  });

  return resultFontFaces;
}
